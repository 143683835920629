import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  product:[],
  loading:false,
  error:''
}

export const counterSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProductStart:(state,action)=>{
        state.loading = true
    },
    getProductSuccess:(state,action)=>{
        state.product = action.payload
        state.loading = false
    },
    getProductFailure:(state,action)=>{
        state.loading = false
        state.error = action.payload
    }

  },
})

// Action creators are generated for each case reducer function
export const { getProductStart,getProductSuccess,getProductFailure } = counterSlice.actions

export default counterSlice.reducer