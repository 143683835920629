import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Catalogpdf from "../../Bayir.pdf"


// import required modules
import { Pagination ,Navigation} from 'swiper/modules';
//components
import AosAnimation from "../../components/AosAnimation"
//css
import "../../style/about.css"
//assets
import About_1 from "../../assets/Image/about/Bayir_about_1.png"
import About_2 from "../../assets/Image/about/Bayir_about_2.png"
import About_3 from "../../assets/Image/about/Bayir_about_3.png"
import About_4 from "../../assets/Image/about/Bayir_about_4.JPG"

import About_5 from "../../assets/Image/about/Bayir_about_5.jpg"
import About_6 from "../../assets/Image/about/Bayir_about_6.jpg"
import team from "../../assets/Image/about/team.png"
import team1 from "../../assets/Image/about/team2.JPG"
import team2 from "../../assets/Image/about/team3.JPG"
//components
import Advantages from "../../components/advantages"
import { useNavigate } from 'react-router-dom';
import Seo from '../../components/seo';
const About = () => {
  const navigate = useNavigate()
  const teamData=[
    {
      id:1,
      fullname:"Нодир Султанов",
      position:"Директор",
      image:team,
    },
    {
      id:2,
      fullname:"Наталья Акопян",
      position:"unposition",
      image:team1,
    },
    {
      id:3,
      fullname:"Саидкомол Музаффаров",
      position:"unposition",
      image:team2,
    },
    {
      id:4,
      fullname:"Нодир Султанов",
      position:"Директор",
      image:team,
    },
    {
      id:5,
      fullname:"Нодир Султанов",
      position:"Директор",
      image:team,
    },
  ]
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const setWindowDimensions = () => {
  setWindowWidth(window.innerWidth)
}
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])
  return (
    <div className='about'>
      <Seo title={'BAYIR | О нас'} description={'"BAYIR" — один из престижных мебельных шоурумов в Узбекистане, который успешно работает на рынке с 1999 года. Мы специализируемся на предоставлении импортной офисной мебели от известных Китайских фабрик, а также мягкой мебели собственного производства, используя только импортную фурнитуру, отвечающую мировому стандарту качества.'}
      keywords={'Мебель'}
      />
      {/* about-header  */}
      <div className='about-header'>
        <AosAnimation dataoas='fade-right'>
        <div className='about-head-f'>
          <h1 className='about-header-text-res'>
          Современная
          </h1>
          <h1 className='about-header-text'>Современная</h1>
          <img src={About_4} alt="About_4" />
          <p className='about-header-text'></p>
        </div>
        </AosAnimation>
        <AosAnimation dataoas='fade-left'>
        <div className='about-head-s'>
          <h1 className='about-header-text'>мебель высокого качества</h1>
          <img src={About_5} alt="About_5" />
          <h1 className='about-header-text-res'>мебель высокого качества</h1>
        </div>
        </AosAnimation>
        <AosAnimation dataoas='fade-right'>
        <div className='about-head-t'>
          <h1 className='about-header-text-res'>для вашего офиса</h1>
          <img src={About_6} alt="About_6" />
          <h1 className='about-header-text'>для вашего офиса</h1>
        </div>
        </AosAnimation>
      </div>
      {/* about-description  */}
      <div className='about-description'>
      <AosAnimation dataoas='fade-up'>
        <h3>О нас</h3>
        <p>
          <span>
          "BAYIR" — один из престижных мебельных шоурумов в Узбекистане, который успешно работает на рынке с 1999 года. Мы специализируемся на предоставлении импортной офисной мебели от известных Китайских фабрик, а также мягкой мебели собственного производства, используя только импортную фурнитуру, отвечающую мировому стандарту качества. </span>
          <span>
          Наша компания гордится репутацией, на протяжении 24-х лет удерживая высокий уровень сервиса и товара. Наша команда профессионалов всегда готова помочь выбрать подходящую мебель, учитывая требования и предпочтения каждого клиента.  </span>
          <span>
          Мы предлагаем широкий ассортимент мебели для офиса, включая столы, стулья, шкафы, полки и другие предметы мебели, которые помогут создать комфортное и эргономичное рабочее пространство.  </span> 
          <span>
          Высококвалифицированные специалисты нашей команды всегда готовы помочь выбрать оптимальное решение для создания комфорта и уникального дизайна ваших офисов. Кроме этого, предлагаем консультацию по дизайну интерьера и воплотим Ваши идеи в реальность. </span>
          <span>
          Мы гордимся тем, что наша компания является надежным партнером для многих организаций, помогая им создавать функциональные и стильные офисы. Мы стремимся поддерживать высокий уровень обслуживания и предлагать только лучшую мебель, чтобы наши клиенты могли достичь успеха в своих деловых задачах.
          </span>
          </p>
          <div className='about-description-btn'>
            <button><a href={Catalogpdf} target='_blank'>Скачать каталог</a></button>
          </div>
        </AosAnimation>
      </div>
      {/* about-advantages */}
      <div className='about-advantages'>
      <Advantages/>
      </div>
      {/* about-team  */}
      {/* <div className='about-team'>
        <div className='about-team-header'>
          <div className='about-title'>Наша команда</div>
        </div>
      <Swiper
        slidesPerView={windowWidth > 1366 ? 4 : windowWidth > 1024 ? 3 : windowWidth > 768 ? 2 :1 }
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {teamData.map(item=>(
        <SwiperSlide>
          <div className='about-after'>
            <div className='disp'>
            <span></span>
            <p onClick={()=>navigate(`/about/team/${item.id}`)}>Подробнее</p>
            </div>
            <img src={item.image} alt="team" />
          </div>
          <div>
            <p className='fullname'>{item.fullname}</p>
            <p className='position'>{item.position}</p>
          </div>
        </SwiperSlide>
        ))}
      </Swiper>
      </div> */}
    </div>
  )
}

export default About