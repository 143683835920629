import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Test = () => {
        toast.warn("Sayt test rejimida ishlamoqda",{
            position: toast.POSITION.BOTTOM_RIGHT,
            theme:"dark"
            
        });
  return (
    <div>
        <ToastContainer />
    </div>
  )
}

export default Test