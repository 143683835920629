import React from 'react';

const NotFound = () => {
  const containerStyle = {
    textAlign: 'center',
    margin: '80px auto',
    fontFamily: 'Arial, sans-serif',
  };

  const titleStyle = {
    fontSize: '48px',
    color: '#f00',
    marginBottom: '16px',
  };

  const messageStyle = {
    fontSize: '18px',
    color: '#666',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>404 - Not Found</h1>
      <p style={messageStyle}>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;