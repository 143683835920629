import Main from "./pages/main";
import About from "./pages/about";
import Partners from "./pages/partners";
import Assembly from "./pages/assembly";
import Contact from "./pages/contact";
import Delivery from "./pages/delivery";
import Mebel from "./pages/mebel";
import Team from "./pages/team";
import Info from "./pages/info";
import Thanks from "./components/Thanks";
import Msg from "./components/msg";
import Chat from "./components/chat";
import Test from "./components/test";
import Complete from "./components/complete";
import Fulldata from "./components/fulldata";
import Completeform from "./components/completeform";
import Completemebel from "./pages/complete-mebel/complete-mebel";

export const routes = [
    {
        id:1,
        url:"/",
        page:<Main/>
    },
    {
        id:2,
        url:"/about",
        page:<About/>
    },
    {
        id:3,
        url:"/partners",
        page:<Partners/>
    },
    {
        id:4,
        url:"/delivery",
        page:<Delivery/>
    },
    {
        id:5,
        url:"/assembly",
        page:<Assembly/>
    },
    {
        id:6,
        url:"/contact",
        page:<Contact/>
    },
    {
        id:6,
        url:"/mebel",
        page:<Mebel/>
    },
    {
        id:6,
        url:"/mebel/:slug",
        page:<Mebel/>
    },
    {
        id:7,
        url:"/about/team/:slug",
        page:<Team/>
    },
    {
        id:8,
        url:"/mebel/:slug/:slug",
        page:<Info/>
    },
    {
        id:9,
        url:"/thanks",
        page:<Thanks/>
    },
    {
        id:10,
        url:"/msg",
        page:<Msg/>
    },
    {
        id:11,
        url:"/chat",
        page:<Chat/>
    },
    {
        id:12,
        url:"/test",
        page:<Test/>
    },
    {
        id:13,
        url:"/complete-mebel/:slug",
        page:<Completemebel/>
    },

]