import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../style/main.css"
import complete_1 from "../../assets/Image/complete/complete_1.JPG"
import complete_2 from "../../assets/Image/complete/complete_2.JPG"
import complete_3 from "../../assets/Image/complete/complete_3.jpeg"
import complete_4 from "../../assets/Image/complete/complete_4.JPG"
import complete_5 from "../../assets/Image/complete/complete_5.JPG"
import complete_6 from "../../assets/Image/complete/complete_6.JPG"
import complete_7 from "../../assets/Image/complete/complete_7.jpeg"
import { useNavigate } from 'react-router-dom';
const MainSwiper = () => {
    const [realIndex, setRealIndex] = useState(0);
    const navigate = useNavigate()
    const datacopm = [
        {
            id:1,
            img:complete_1,
          },
          {
            id:2,
            img:complete_7,
          },
          {
            id:3,
            img:complete_2,
          },
          {
            id:4,
            img:complete_2,
          },
          {
            id:5,
            img:complete_3,
          },
          {
            id:6,
            img:complete_4,
          },
          {
            id:7,
            img:complete_5,
          },
          {
            id:8,
            img:complete_6,
          },
          {
            id:9,
            img:complete_6,
          },
          
    ]
  return (
    <div>
        <div className="main" style={{padding:"3%",margin:"0 auto"}}>
                <h1 className="compelct-title">Комплекты</h1>
                <div className="main-new-t">
                <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        navigation={true}
                        pagination={{
                            type: "progressbar",
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                        onSlideChange={(e) => {
                            setRealIndex(e.realIndex);
                        }}
                    >
                        {datacopm.map((item,key) => (
                            <SwiperSlide onClick={()=>navigate(`/complete-mebel/${item.id}`)} key={key}>
                              <div className='over-img'>
                               <img src={item.img} alt="item" className="comp-img" />
                               <span id='readmore'>Больше информации</span>
                               <p id='completeparda'></p>
                               </div>
                            </SwiperSlide>
                        ))}
                        <div className="main_pagination">
                            <p>{(realIndex + 1).toString().padStart(2, 0)}</p>
                            <p style={{ marginLeft: "363px" }}>{datacopm.length > 9 ? datacopm.length : '0' + datacopm.length}</p>
                        </div>
                    </Swiper>
                </div>
            </div>
    </div>
  )
}

export default MainSwiper