import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineSend } from 'react-icons/ai'
//assets
import logo from "../assets/Image/main/Vector.svg"
import maria from "../assets/Image/about/zaybal.jpg"
import left from "../assets/Image/about/left.svg"
import right from "../assets/Image/about/right.svg"
//styles
import "../style/chat.css"
import { ItemContext } from '../context/ItemContext'
import { ToastContainer, toast } from 'react-toastify'
const Chat = () => {
  const d = new Date();
  const [time,setTime] = useState(d)
  const allMessages = [
    {
      role:'company',
      message:"Здравствуйте! Как мы можем Вам помочь?",
      time:d.getHours() + ':' + d.getMinutes(),
    },
    
  ]
  const [allmessage,setAllMessage] = useState(allMessages)
  const [message,setMessage] = useState('')
  const changeHandler=(e)=>{
    if(e.target.value === ""){
      setMessage("")
    }
    e.target.value.split("").map(item=>{
      if(item===" " || item==='\n'){
        return null
      }
      else{
        setMessage(e.target.value)
      }
    })
  }

  const askData = () =>{
    const newMes = {
      role:'company',
      message:"Пожалуйста, оставьте свое имя и номер телефона",
      time:d.getHours() + ':' + d.getMinutes(),
    }
    if(allmessage.length<=2){
      setTimeout(()=>{
        setAllMessage(prev=>[...prev,newMes])
      },3000)
    }
    const newMes2 = {
      role:'company',
      message:"Наши сотрудники свяжутся с Вами в ближайшее время",
      time:d.getHours() + ':' + d.getMinutes(),
    }
    if(allmessage.length===3){
      setTimeout(()=>{
        setAllMessage(prev=>[...prev,newMes2])
      },3000)
    }
  }
  const sendMessage = () =>{
    if(message !== ''){
    const emoji = [
        {
          emoji: "💬",
          description: "speech balloon",
          category: "Smileys & Emotion",
          aliases: ["speech_balloon"],
          tags: ["comment"],
          unicode_version: "6.0",
          ios_version: "6.0",
        },
      ]
      var text = `%0A ${emoji[0].emoji} <b>Client:</b><i class="fa fa-check-square">${message}</i>`

      var token = "6370568112:AAE4RXoRJQxvXsJhhI805DnBgj6jNVBYWnA"
      var chat_id = -1001949757966;   
      var url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${text}&parse_mode=html`;
      let api = new XMLHttpRequest()
      api.open("GET",url,true)
      api.send()
    }
    if(message===" " || message==='\n' || message.length === 0){
      return null
    }
    else{

      const newMes = {
        role:'user',
        message:message,
        time:d.getHours() + ':' + d.getMinutes(),
      }
  
      setAllMessage(prev=>[...prev,newMes])
      setMessage('')
      askData()
    }
  }
  const getkey = (e) =>{
    if(e.nativeEvent.key === "Enter"){
      sendMessage()
    }
  }
  const {setChat} = useContext(ItemContext)
  return (
    <div className='chat' onKeyUp={getkey}>
      <div className='chat-in'>
        <div className='chat-top'>
            <div className='chat-top-first'>
              <img src={maria} alt="maria" />
              <div>
                <h2>Мария Эль</h2>
                <p>Консультант</p>
            </div>
            </div>
            
            <div className='chat-top-third' onClick={()=>setChat(false)}>
              <span className='chat-close'><AiOutlineClose/></span>
            </div>
         
        </div>
        <hr />
        <div className='chat-middle'>
          {allmessage.map(item=>(
            <div className='chat-sector'>
              {item.role === "user" ? null : <>
              <span>{item?.time} &nbsp;&nbsp;&nbsp;</span>
               <img src={left} alt="left" id /></>
                }
               
            <div className='chat-mes' id={`${item.role === "user" ? "user" : ""}`}>
              <p>{item?.message}</p>
            </div>

            {item.role === "user" ? <><img src={right} alt="right" />
            <span>{item?.time}</span></> : null }
            
            </div>
          ))}
        </div>
        <div className='chat-bottom'>
            <input type="text" placeholder='Введите сообщение...' value={message} onChange={changeHandler} minLength={5}/>
            <p className={`${message?.length === 0 ? 'send' : ""}`} onClick={sendMessage}><AiOutlineSend style={{fontSize:"40px",color:"red"}} id='send'/></p>
          </div>
          <br />
        </div>
    </div>
  )
}

export default Chat