import React,{ useState,  } from 'react'
import { useSelector } from 'react-redux';


import ScaleLoader from "react-spinners/ScaleLoader";
import "../../style/loader.css"



const Loader = ({load = false}) => {
    const {loading} = useSelector((state)=>state.productSlice)

  return (
    <div className='loader'>
    <div className="loader__container">
      <ScaleLoader
        color={"white"}
        radius={10}
        loading={load || loading}
        size={150}
        width={20}
        height={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
    </div>
  )
}

export default Loader