import React, { useContext, useEffect, useState } from 'react'


import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../../style/info.css"
import "../../style/main.css"

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

// redux 
import {  useSelector } from 'react-redux';
import { ItemContext } from '../../context/ItemContext';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// components
import Thanks from '../../components/Thanks';
import Msg from '../../components/msg';
import Loader from '../../components/loader/loader';
import MebelTypeLink from '../../mebelTypes';

const Info = () => {
    
    const {slug} = useParams()
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const {setItem,form,setForm,close} = useContext(ItemContext)
    const {product,loading} = useSelector(state=>state.productSlice)

    
    const [itemet,setItemet] = useState([])
    const [statet,setStatet] = useState([])
    const [changeLoading,setChangeLoading] = useState(false)
    const [realIndex, setRealIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    
   

    const mebelFilter = async () =>{
        try {
            const findProduct = await product.find(c=>c.name === slug)
            const fiterProduct = await product.filter(c=>c.title === findProduct.title)
            setItemet(findProduct)
            setStatet(fiterProduct)
            
        } catch (error) {
            navigate('/')
        }
    }

    const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    }


  const changeItem=(info)=>{
        setChangeLoading(true)
        setTimeout(()=>{
            setChangeLoading(false)
        },1500)
        setItem(info)
        setItemet(info)
        navigate(`/mebel/${pathname.split('/')[2]}/${info.name}`)
        window.scrollTo({top:0, behavior:'smooth'})
  }
    useEffect(() => {
        mebelFilter()
      window.addEventListener('resize', setWindowDimensions);
      return () => {
        window.removeEventListener('resize', setWindowDimensions)
      }
    }, [])
    
  return (
    (changeLoading || loading) ? <Loader load={true}/> : <>
    <div className='info'>
    
        <div className='info-item'>
        <div className='info-images'>
            <div className='info-head-img'>
                <img src={itemet.main_image} alt="mebel" />
            </div>
           
        </div>
        <div className='info-data'>
        <div className='line__slug'>
        <Link to={'/'}>Главная</Link>
        <p>/</p>
        <Link to={`/mebel/${pathname.split('/')[2]}`}>{MebelTypeLink.find(c=> c.typeLinkUz === pathname.split('/')[2])?.typeLinkRu}</Link>
        <p>/</p>
        <Link to={`/mebel/${pathname.split('/')[2]}/${slug}`}>{slug}</Link>
      </div>
            <div className='info-data-item'>
                <p className='title'>{itemet.title}</p>
                <h2 className='subtitle'>{itemet.name}</h2>
                <p className='description'>Размер {itemet.size}</p>
                {console.log(itemet?.description?.replaceAll('<br/>',' '))}
                <p className='description'  dangerouslySetInnerHTML={{
            __html: itemet?.description?.replaceAll('&lt;br/&gt;',' ')
          }} />
                <div className='costs'>
                <strike>{itemet.old && itemet.old}</strike>
                    <p>{itemet.new && itemet.new + " сум"}</p>
                </div>
                <button onClick={()=>setForm(true)}>оставить заявку</button>
            </div>
        </div>
        </div>
        <div className='suggestion'>
        <div className='main'>
            <h1 className='main-title'>Рекомендуемые товары</h1>
        <div className="main-new">
                    <Swiper
                        slidesPerView={windowWidth > 1366 ? 4 : windowWidth > 1024 ? 3 : windowWidth > 768 ? 2 : 1}
                        spaceBetween={30}
                        navigation={true}
                        pagination={{
                            type: "progressbar",
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                        onSlideChange={(e) => {
                            setRealIndex(e.realIndex);
                        }}
                    >
                        {statet.map((item) => (
                            <SwiperSlide>
                                <div className="piece-box-item">
                                    <div className="bg-img">
                                    <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>changeItem(item)}>Подробнее</p>
            </div>
                                        {item.sale && (
                                            <p className="sale">{item.sale === null ? "" : item.sale + " %"}</p>
                                        )}
                                        <img src={item.main_image} alt={item.title} />
                                    </div>
                                    <div>
                                        <div className="piece-item-title"><p>{item.title}</p> <b>{item.name}</b></div>
                                        <div className="const">
                                            <b id="old">
                                                <strike>{item.old && item.old}</strike>
                                            </b>
                                            <b id="new">{item.new && item.new + " сум"}</b>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="main_pagination">
                            <p>{(realIndex + 1).toString().padStart(2, 0)}</p>
                            <p style={{ marginLeft: "363px" }}>{statet.length}</p>
                        </div>
                    </Swiper>
                </div>
                <div className='res-main-new'>
                {statet.map((item) => (
                                <div className="piece-box-item">
                                    <div className="bg-img">
                                        <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>changeItem(item)}>Подробнее</p>
            </div>
                                        {item.sale && (
                                            <p className="sale">{item.sale === null ? "" : item.sale + " %"}</p>
                                        )}
                                        <img src={item.main_image} alt={item.title} />
                                    </div>
                                    <div>
                                     
                                        <div className="piece-item-title">{item.title} <b>{item.name}</b> </div>
                                        <div className="const">
                                            <b id="old">
                                                <strike>{item.old && item.old}</strike>
                                            </b>
                                            <b id="new">{item.new && item.new + " сум"}</b>
                                        </div>
                                    </div>
                                </div>
                        ))}           
                                    
                </div>
                </div>
                </div>
                {form ? <Thanks/> : null}
                {close ? null : <Msg/> } 
                
    </div>
                </>
  )
}

export default Info