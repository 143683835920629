import React from 'react'
import "../style/complete.css"
import { useNavigate, useParams } from 'react-router-dom'
const Completeform = () => {
    const {slug}  = useParams()
    const navigate = useNavigate()

    const DYMMY_DATA = [
        {
            id:1,
            name:`"Presidential Gold"`,
            all_cost:"85 600 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол модель BDL-01D24  из экологического шпона из МДФ класса E1. Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. (Перед сборкой тумбу можно установить как справа так и слева).  Размер: 2400W*1800D*760H. Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый.`,
                    cost:"35 800 000",
                },
                {
                    title:"Шкаф",
                    description:`Шкаф модель BDL-01S30 из экологического шпона из МДФ класса E1. Размер: 3000W*400D*2100H. Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый. С подстветкой.`,
                    cost:"41 600 000",
                },
                {
                    title:"Комод",
                    description:`Комод модель BDL-01H12 из экологического шпона из МДФ класса E1. Размер: 1200W*400D*900H.  Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый`,
                    cost:"8 200 000",
                },
            ]
        },
        {
            id:2,
            name:`"Rose-Gold Royalty"`,
            all_cost:"67 650 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол  модель BBT-01D24 из экологического шпона из МДФ класса E1. Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. (Перед сборкой тумбу можно установить как справа так и слева). Размер: 2400W*1900D*760H. Цвет: Розовое золото + песочно-коричневый. Стол с подсветкой. `,
                    cost:"29 400 000",
                },
                {
                    title:"Шкаф",
                    description:`Шкаф модель BBT-01S30 из экологического шпона из МДФ класса E1. Размер 3000W*600D*2100H.  Цвет: Розовое золото + песочно-коричневый. С подсветкой. `,
                    cost:"38 250 000",
                },
              
            ]
        },
        {
            id:3,
            name:`"Wooden Loft I"`,
            all_cost:"45 850 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол модель BBP-D0124 из экологически чистой  ДСП класса Е1 с меламиновой отделкой. Перед сборкой длинную тумбу можно поменять местами слева или справа.Размер: 2400*1820*760.  Цвет: Поп-Дуб + Небесный Мокко`,
                    cost:"17 250 000 ",
                },
                {
                    title:"Шкаф",
                    description:`Шкаф модель BBP-S0130 из экологически чистой ДСП класса Е1 с меламиновой отделкой. Размер: 3000*400*2000. Цвет: Поп-Дуб + Небесный Мокко.  `,
                    cost:"24 350 00",
                },
                {
                    title:"Комод",
                    description:`Комод модель BBP-H0112 из экологически ДСП класса E1 с импортной меламиновой отделкой Schattdecor. Размер:1200W*900D*400H. Цвет: Поп-Дуб + Небесный Мокко `,
                    cost:"4 250 000",
                },
              
            ]
        },
        {
            id:4,
            name:`"Wooden Loft II"`,
            all_cost:"46 850 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол модель BBP-D0128 из экологически чистой  ДСП класса Е1 с меламиновой отделкой. Перед сборкой длинную тумбу можно поменять местами слева или справа.Размер: 2800*2100*760.  Цвет: Поп-Дуб + Небесный Мокко. `,
                    cost:"18 250 000",
                },
                {
                    title:"Шкаф",
                    description:` Шкаф модель BBP-S0130 из экологически чистой ДСП класса Е1 с меламиновой отделкой. Размер: 3000*400*2000. Цвет: Поп-Дуб + Небесный Мокко. `,
                    cost:"24 350 000",
                },
                {
                    title:"Комод",
                    description:`Комод модель BBP-H0112 из экологически ДСП класса E1 с импортной меламиновой отделкой Schattdecor. Размер:1200W*900D*400H. Цвет: Поп-Дуб + Небесный Мокко. `,
                    cost:"4 250 000",
                },
              
            ]
        },
        {
            id:5,
            name:`"Nude Classic"`,
            all_cost:"38 600 000",
            childrens:[
                {
                    title:"Стол",
                    description:`Стол модель BLL-D0124 из экологически чистой ДСП класса Е1 с Меламиновой отделкой.Фиксированная длинная тумба под принтер с левой стороны. Размер: 2400*1900*760. Цвет: Серый дуб + железно-серый. `,
                    cost:"17 750 000",
                },
                {
                    title:"Шкаф",
                    description:` Шкаф модель BLL-S0124 из экологически чистой ДСП класса Е1 с меламиновой отделкой. Размер: 2400*400*2100. Цвет: Серый дуб + железно-серый.  `,
                    cost:"14 450 000",
                },
                {
                    title:"Комод",
                    description:`Комод модель BLL-H0113 из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor". Размер:1350W*400D*850H. Цвет: Серый дуб + железно-серый. `,
                    cost:"6 400 000",
                },
              
            ]
        },
        {
            id:6,
            name:`"Rose Wood Premium"`,
            all_cost:"91 450 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол из экологического шпона из МДФ класса E1. Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. (Перед сборкой тумбу можно установить как справа так и слева). Размер: 2400W*1825D*760H. Цвет: Зеленый грейпфрут + светло-серый. Стол с подсветкой.  `,
                    cost:"36 550 000",
                },
                {
                    title:"Шкаф",
                    description:` Шкаф из экологического шпона из МДФ класса E1. Размер: 3040W*400D*2000H.  Цвет: Зеленый грейпфрут + светло-серый. С подсветкой.   `,
                    cost:"44 100 000",
                },
                {
                    title:"Комод",
                    description:`Комод из экологического шпона из МДФ класса E1. Размер: 1600W*400D*1000H.  Цвет: Зеленый грейпфрут + светло-серый `,
                    cost:"10 800 000",
                },
              
            ]
        },
        {
            id:7,
            name:`"Modern Blue"`,
            all_cost:"85 700 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:` Руководительский стол с вытянутой ножкой из экологического шпона из МДФ класса E1. Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. (Перед сборкой тумбу можно установить как справа так и слева).  Размер: 2560*2000*760. Цвет: Лазурно-синий + голубино-серый.  `,
                    cost:"35 300 000",
                },
                {
                    title:"Шкаф",
                    description:` Шкаф из экологического шпона из МДФ класса E1. К шкафу прилагаются открытые полки для хранения файлов и закрытие полки для хранения документов. Размер: 3200*400*2000.  Цвет: Лазурно-синий +бежево- серый. С подсветкой  `,
                    cost:"40 300 000",
                },
                {
                    title:"Комод",
                    description:`Комод из экологически чистого  МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8-слойная окраска на водной основе. К комоду приложенны две распашные двери с плавно закрывающимися петлями и скрытой ручкой. Размер:1600*400*900.  `,
                    cost:"10 100 000",
                },
              
            ]
        },
        {
            id:8,
            name:`"Solid-Wood Presidential"I`,
            all_cost:"89 950 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол из североамериканского цельного дерева, столешница из МДФ класса E1 с натуральной облицовкой, 8-слойная лаковая окраска. Размер: 2000W*900D*760H.  `,
                    cost:"41 600 000",
                },
                {
                    title:"Шкаф",
                    description:`Шкаф из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску.
                    Размер:910W*450D*2085H
                    Дверца из закаленного стекла темно-коричневого цвета.  `,
                    cost:"26 450 000",
                },
                {
                    title:"Комод",
                    description:`Комод из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску. Размер:1500W*450D*800H.  `,
                    cost:"21 900 000",
                },
              
            ]
        },
        {
            id:9,
            name:`"Solid-Wood Presidential II"`,
            all_cost:"104 700 000",
            childrens:[
                {
                    title:"Руководительский стол",
                    description:`Руководительский стол из североамериканского цельного дерева, столешница из МДФ класса E1 с натуральной облицовкой, 8-слойная лаковая окраска. Размер: 2400W*950D*760H. `,
                    cost:"47 550 000",
                },
                {
                    title:"Шкаф",
                    description:`Шкаф из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску.
                    Размер:1980W*500D*2085H
                    Дверца из закаленного стекла темно-коричневого цвета. .
                    Размер:910W*450D*2085H
                    Дверца из закаленного стекла темно-коричневого цвета.  `,
                    cost:"35 250 000",
                },
                {
                    title:"Комод",
                    description:`Комод из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску. Размер:1500W*450D*800H.  `,
                    cost:"21 900 000",
                },
              
            ]
        },
    ]
    const filterData = DYMMY_DATA.find(c=>c.id === Number(slug))
    if(!filterData){
        navigate('/')
      }
  return (
    <div className='completeform'>
        {filterData ?
        <div className='mebel-info'>
            <p className='compelete-title'>Комплект мебели</p>
            <h1 className='compelete-name'>{filterData.name}</h1>
            <p className='compelete-description'>Имеется опция дополнений к комплекту за отдельную стоимость (конференц стол/ журнальный стол).</p>
            
            {filterData?.childrens?.map(item=>(
            <div className='mebel-info'>
            <h2 className='compelete-name' id='compelete-name'>{item?.title}</h2>
            <p className='compelete-description'>{item?.description}</p>
            <p className='compelete-cost'>{item?.cost} сум</p>
        </div>

            ))}
            <br />
            <p className='compelete-cost'>Цена комплекта: {filterData.all_cost} сум</p>
            <br />
        </div>
    : navigate('/')}
        
    </div>
  )
}

export default Completeform