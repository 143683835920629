import React, {  useContext, useEffect, useRef, useState } from 'react'

//style
import "../../style/navbar.css"

//icons
import  {BsChevronDown} from "react-icons/bs"

//assets
import logo from "../../assets/Image/footer/bayir2.svg"
import call from "../../assets/Image/footer/icon_phone.svg"

//router
import { Link,useLocation } from 'react-router-dom'
import { ItemContext } from '../../context/ItemContext'


const Navbar = () => {
  
  const changeSlug = ()=>{
    setDropDown(true)
    localStorage.setItem('check', JSON.stringify("Штучно"));
  }
  const {pathname} = useLocation()
  // const [dropdown,setDropDown] = useState(true)
  const {dropdown,setDropDown} = useContext(ItemContext)
 
  return (
    <div className='navbar'>
      <div className="nav">

      
      <div className='navbar_logo'>
        <Link to={'/'}><img src={logo} alt="logo" /></Link>
      </div>
      <div className='navbar_nav'>
        <Link id='dropdown' onClick={()=>setDropDown(prev=>!prev)} className={`${(pathname === "/mebel") ? "active" : "navbar_link"}`} >Мебель &nbsp; <BsChevronDown/></Link>
        <Link to={"/about"} onClick={()=>setDropDown(true)} className={`${(pathname === "/about") ? "active" : "navbar_link"}`}>О нас</Link>
        <Link to={"/partners"} onClick={()=>setDropDown(true)} className={`${(pathname === "/partners") ? "active" : "navbar_link"}`}>Нам доверяют</Link>
        <Link to={"/delivery"} onClick={()=>setDropDown(true)} className={`${(pathname === "/delivery") ? "active" : "navbar_link"}`}>Доставка и оплата</Link>
        <Link to={"/assembly"} onClick={()=>setDropDown(true)} className={`${(pathname === "/assembly") ? "active" : "navbar_link"}`}>Сборка</Link>
        <Link to={"/contact"} onClick={()=>setDropDown(true)} className={`${(pathname === "/contact") ? "active" : "navbar_link"}`}>Контакты</Link>
      </div>
      <a href='tel:+998712357447' className='navbar_phone'>
        <img src={call} alt="call" />
        <p>+998 94 <span>210-74-47</span></p>
      </a>
      </div>
      <div className={`dropdown ${dropdown ? 'open' : ""}`}  >
        <div className='nav_item'>
          <div className='nav_item_link'><Link to={"/mebel/stol-dlya-rukovoditeley"}  onClick={changeSlug} >Столы для руководителей</Link></div>
          <div className='nav_item_link'><Link to={"/mebel/stol-dlya-sotrudnikov"}  onClick={changeSlug} >Столы для сотрудников</Link></div>
          <div className='nav_item_link'><Link to={"/mebel/stol-dlya-peregovorov"}  onClick={changeSlug} >Столы для переговоров</Link></div>

          {/* <div className='nav_item_link'><Link to={"/mebel/Столы для менеджеров"}  onClick={changeSlug} >Столы для менеджеров</Link></div> */}


        </div>
        <div className='nav_item'>
          <div className='nav_item_link'><Link to={"/mebel/open-space"}  onClick={changeSlug} >Столы Open Space</Link></div>
          <div className='nav_item_link'><Link to={"/mebel/jurnalniye-stoliki"}  onClick={changeSlug} >Журнальные столики</Link></div>
          <div className='nav_item_link'><Link to={"/mebel/resepshn-stol"}  onClick={changeSlug} >Столы Reception</Link></div>
          
   
         
         
       

        </div>
        <div className='nav_item'>
        <div className='nav_item_link'><Link to={"/mebel/modulnaya-mebel"}  onClick={changeSlug} >Модульная Мебель</Link></div>
        <div className='nav_item_link'><Link to={"/mebel/myagkaya-mebel"}  onClick={changeSlug} >Мягкая Мебель</Link></div>
          <div className='nav_item_link'><Link to={"/mebel/shkafi"}  onClick={changeSlug} >Шкафы</Link></div>
          
        </div>
        <div className='nav_item'>
        <div className='nav_item_link'><Link to={"/mebel/komodi"}  onClick={changeSlug} >Комоды</Link></div>
        <div className='nav_item_link'><Link to={"/mebel/kresla"}  onClick={changeSlug} >Кресла</Link></div>
          <div className='nav_item_link' id='nav-visible'><Link to={"/mebel/Open Space"}  onClick={changeSlug} >Столы Open Space</Link></div>
        </div>
       
      </div>
    </div>
    // журнальные столики
  )
}

export default Navbar