import React from 'react'
import ContactCom from '../../components/contact'
import {BsFillTelephoneFill} from "react-icons/bs"
import {MdLocationPin} from "react-icons/md"
import {AiFillClockCircle} from "react-icons/ai"
import '../../style/contact.css'
import AosAnimation from "../../components/AosAnimation"
import Seo from '../../components/seo'
const Contact = () => {
  return (
    <div>
      <Seo title={'BAYIR | Контакты'} description={'Связаться с нами +998 (71) 235-74-47, +998 (94) 643-76-28, +998 (90) 051-80-90,Адрес: г. Ташкент, ул. Амир Темур- Эргашев 2'}/>
      <AosAnimation dataoas='fade-down'>
      <div className='contact'>
      <div className='contact_phone'>
        <p>Связаться с нами</p>
        <h3>Контакты</h3>
      </div>
      <div className='contact_col'>
          <div className='icontitle'>
            <span><BsFillTelephoneFill/></span>
            <p>Телефон:</p>
          </div>
       
          <p><a href="tel:+998712357447">+998 (71) 235-74-47</a></p>
          <p><a href="tel:+998946437628">+998 (94) 643-76-28</a></p>
          <p></p>
      </div>
      <div className='contact_col'>
          <div className='icontitle'>
            <span><MdLocationPin/></span>
            <p>Адрес:</p>
          </div>
          <p>• г. Ташкент, ул. Амир Темур- Эргашев 2</p>
          <p style={{paddingRight:'5px'}}>• г. Ташкент, ул. Махтум-кули, 75 ARCA Mebel Markazi</p>
      </div>
      <div className='contact_col' style={{border:"none"}}>
          <div className='icontitle' >
            <span><AiFillClockCircle/></span>
            <p>Часы работы:</p>
          </div>
          <p>Пн- сб, 9:30 - 18:30</p>
      </div>
      </div>
      </AosAnimation>
      <ContactCom/>
      
    </div>
  )
}

export default Contact