import React, { useEffect, useState } from 'react'
import Piece from '../../components/piece'
import NewPiece from '../../components/newpiece'
import MebelTypeLink from '../../mebelTypes'
import "../../style/mebel.css"
import  {BsChevronDown,BsChevronUp} from "react-icons/bs"
import { Link, useParams } from 'react-router-dom'
import Seo from '../../components/seo'
const Mebel = () => {
  const {slug} = useParams()
  
    const [filterSearch,setFilterSearch] = useState(true)
    const [filterCategory,setFilterCategory] = useState(false)
    const [filterCoating,setFilterCoating] = useState(false)
    const [filterMebel,setFilterMebel] = useState(true)
    // State with list of all checked item
    const [check,setCheck] = useState(false)
    const [checkedAll, setCheckedAll] = useState(['Штучно']);
    const [checked, setChecked] = useState([]);
    const [checkedMebel, setCheckedMebel] = useState([]);
    // const Category = ["Штучно", "Комплект"];
    const Coating =  ["МДФ", "ЛДСП", "Солид"];
    const Mebel = ["Столы для руководителей", "Столы для сотрудников","Столы для менеджеров", "Столы для переговоров", "Столы Open Space","Конференц столы","Приставка","Журнальный столик","Стойка регистрации", "Модульная мебель", "Мягкая мебель", "Шкафы", "Комоды","Кресла",];
    // Add/Remove checked item from list
    const handleCheck = (event) => {
      var updatedList = [...checked];
      if (event.target.checked) {
        updatedList = [...checked, event.target.value];
      } else {
        updatedList.splice(checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
    };
    const handleCheckMebel = (event) => {
      var updatedList = [...checkedMebel];
      if (event.target.checked) {
        updatedList = [...checkedMebel, event.target.value];
      } else {
        updatedList.splice(checkedMebel.indexOf(event.target.value), 1);
      }
      setCheckedMebel(updatedList);
    };
    const handleCheckAll = (event) => {
      localStorage.setItem('check', JSON.stringify(event.target.value));
      var updatedList = [];
      if (event.target.checked) {
        updatedList = [event.target.value];
      }
      setCheckedAll(updatedList);
    };

  return (
    <div className='mebel'>
      <Seo title={`BAYIR | ${MebelTypeLink.find(c=> c.typeLinkUz === slug)?.typeLinkRu}`} description={'"BAYIR" — один из престижных мебельных шоурумов в Узбекистане, который успешно работает на рынке с 1999 года. Мы специализируемся на предоставлении импортной офисной мебели от известных Китайских фабрик, а также мягкой мебели собственного производства, используя только импортную фурнитуру, отвечающую мировому стандарту качества.'}
      keywords={'Мебель'}
      />
      <div className='line__slug'>
        <Link to={'/'}>Главная</Link>
        <p>/</p>
        <Link to={`/mebel/${slug}`}>{MebelTypeLink.find(c=> c.typeLinkUz === slug)?.typeLinkRu}</Link>
      </div>
      <h1 className='mebel-title'>ОФИСНАЯ МЕБЕЛЬ</h1>
      <div className='mebel-grid'>
        <div className='mebel-filter'>
        <div className="checkList">
        <div className="filter-title" onClick={()=>setFilterSearch(prev=>!prev)}>
          <p>Фильтры для поиска</p>
          <p className='filter-title-icon'>{filterSearch ? <BsChevronDown/>: <BsChevronUp/> }</p>
        </div>
        <div className="list-container" id={`${filterSearch ? "close-container" : ""}`}>
          <div>
          <div className='category-title' onClick={()=>setFilterCategory(prev=>!prev)}>
            <p>Категории</p>
            <p id="icon-category">{filterCategory ? <BsChevronDown/>: <BsChevronUp/> }</p>
          </div>
            <div  className='input-section' id={`${filterCategory ? "filterCategory" : ""}`} >
              <input value={"Штучно"} type="checkbox" checked={!check}  onChange={handleCheckAll} onClick={()=>setCheck(prev=>!prev)}/>
              <span>Штучно</span>
            </div>
            <div  className='input-section' id={`${filterCategory ? "filterCategory" : ""}`}>
            <input value={"Комплект"} type="checkbox" checked={check} onChange={handleCheckAll} onClick={()=>setCheck(prev=>!prev)}/>
            <span>Комплект</span>
          </div>
          
          </div>
          <div>
          <div className='category-title' onClick={()=>setFilterCoating(prev=>!prev)}>
            <p>Покрытие</p>
            <p>{filterCoating ? <BsChevronDown/>: <BsChevronUp/> }</p>
          </div>
          {Coating.map((item, index) => (
            <div key={index} className='input-section' id={`${filterCoating ? "filterCoating" : ""}`}>
              <input value={item} type="checkbox" onChange={handleCheck} />
              <span>{item}</span>
            </div>
          ))}
          <div>
          
          </div>
          </div>
        </div>
      </div>

     
        </div>
        <div className='cards'>
          {slug === undefined 
          ? 
          <Piece checked={checked}  checkedMebel={checkedMebel}/> 
          :
          <NewPiece checked={checked}  checkedMebel={checkedMebel}/>
          }
        
        </div>
      </div>
    </div>
  )
}

export default Mebel