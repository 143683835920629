import React from 'react'

//compnents
import ContactCom from '../../components/contact'
import AosAnimation from "../../components/AosAnimation"

//css
import "../../style/delivery.css"
//assets
import delivery_1 from "../../assets/Image/delivery/delivery_1.png"
import delivery_2 from "../../assets/Image/delivery/delivery_2.png"
import delivery_3 from "../../assets/Image/delivery/delivery_3.png"
import Seo from '../../components/seo'
const Delivery = () => {
  return (
    <div>
    <Seo title={'BAYIR | Доставка'} description={'Доставка офисной мебели в пределах города Ташкент осуществляется – БЕСПЛАТНО!'}/>
        <div className='delivery'>
        <AosAnimation dataoas='fade-right'>

        <div className='text_image'>
            <div>
                <h3>ДОСТАВКА</h3>
                <h5>Доставка офисной мебели в пределах города Ташкент осуществляется – БЕСПЛАТНО!</h5>
                <p>Не стоит ломать голову над тем, кто привезет Вашу мебель, просто доверьтесь нам, и мы доставим ее до места сборки! 
</p>
<p>
Служба доставки работает ежедневно с 9-00 до 18-00, кроме воскресенья. Вся офисная мебель, приобретенная в нашем магазине, поставляется в фабричной упаковке в разобранном виде. Доставка товара осуществляется в течение 3-х рабочих дней с момента поступления 100%</p>
<p style={{color:"#2B2A29", opacity:"50%"}}>
ВАЖНО - Для быстрой разгрузки мебели, покупателю нужно освободить помещение, в котором будут проводиться монтаж мебели, а также освободить проемы и проходы, через которые будет осуществляться занос мебели.</p>
            </div>
            <div>
                <img src={delivery_1} alt="delivery_1" />
            </div>
        </div>
        </AosAnimation>

        <br />
        <AosAnimation dataoas='fade-right'>
        <div className='image_text'>
            <div>
                <img src={delivery_2} alt="delivery_2" />
            </div>
            <div className='row'>
            <h3>Оплата</h3>
                <h5>Для Вашего удобства мы предлагаем разнообразные способы оплаты:</h5>
              
                <ul>
                  <li>Перечислением</li>
                  <li>Оплата наличными</li>
                  <li>Оплата по терминалу</li>
                </ul>
                <p style={{color:"#2B2A29", opacity:"50%"}}>
                При любом виде оплаты выдаются все необходимые документы в соответствии с Законодательством Республики Узбекистан.</p>
<p style={{color:"#2B2A29", opacity:"50%"}}>
Большой ассортимент мебели и гибкая система скидок позволит Вам выбрать мебель по своему вкусу.</p>
            </div>
        </div>
        </AosAnimation>
        <br />
        <AosAnimation dataoas='fade-left'>
        <div className='text_image2'>
            <div>
                <h3>Реквизиты</h3>
                <div className='rekvizit'>
                  <div>ЧП «QORA BAYIR SAVDO»</div>
                  <div>г.Ташкент, Ул.А.Тимура-Эргашева,2</div>
                  <div>тел. 71 235-74-47</div>
                  <div>Р/с 20208000904254383001</div>
                  <div>АКБ «Asia Alliance Bank» </div>
                  <div>ИНН 204493771 </div>
                  <div>МФО 01057</div>
                  <div>ОКЭД 46650</div>
                  <div>Регистрационный номер НДС 326030015383</div>
                </div>
            </div>
            <div>
                <img src={delivery_3} alt="delivery_3" />
            </div>
        </div>
        </AosAnimation>
        </div>
        <ContactCom/>
    </div>
  )
}

export default Delivery