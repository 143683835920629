import React, { useContext } from 'react'
import "../style/msg.css"
import { AiOutlineClose } from 'react-icons/ai'
import { ItemContext } from '../context/ItemContext'
const Msg = () => {
    const {setClose} = useContext(ItemContext)
  return (
    <div>
    <div className='msg'>
    </div>
        <div className='message'>
        <div>
        <p id='msg-icon' onClick={()=>setClose(true)}><AiOutlineClose/></p>
        <h2>Спасибо!</h2>
        <p id='msg-text'>Ваша заявка принята, наши менеджеры свяжутся с вами в ближайшее время</p>
        </div>
        </div>
    </div>
  )
}

export default Msg