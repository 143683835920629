import React, { useContext, useRef } from 'react'
//toasify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//components
import AosAnimation from "../AosAnimation"
// style
import "../../style/contactcom.css"
import { ItemContext } from '../../context/ItemContext'
import Msg from '../msg'
const Contact = () => {
    
    const {close,setClose} = useContext(ItemContext)
    const ref = useRef(null);
    const postData=(e)=>{
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        const name = data.get('name')
        const email = data.get('email')
        const phone = data.get('phone')
        const date = new Date()
        const day = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
        const time = `${date.getHours()}:${date.getMinutes()}`
        
      const  emoji = [
            {
              emoji: "✅",
              description: "check mark button",
              category: "Symbols",
              aliases: ["white_check_mark"],
              tags: [],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "📞",
              description: "telephone receiver",
              category: "Objects",
              aliases: ["telephone_receiver"],
              tags: ["phone", "call"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "⏰",
              description: "alarm clock",
              category: "Travel & Places",
              aliases: ["alarm_clock"],
              tags: ["morning"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "👤",
              description: "bust in silhouette",
              category: "People & Body",
              aliases: ["bust_in_silhouette"],
              tags: ["user"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "💬",
              description: "speech balloon",
              category: "Smileys & Emotion",
              aliases: ["speech_balloon"],
              tags: ["comment"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "📆",
              description: "tear-off calendar",
              category: "Objects",
              aliases: ["calendar"],
              tags: ["schedule"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
          ];
          var text = `%0A ${emoji[3].emoji} <b>Name:</b><i class="fa fa-check-square">${name}</i>%0A ${emoji[1].emoji} <b>Number:</b><i class="fa fa-check-square">${phone}</i>%0A ${emoji[4].emoji} <b>Email:</b><i class="fa fa-check-square">${email}</i>%0A ${emoji[5].emoji} <b>Date:</b><i class="fa fa-check-square">${day}</i> %0A ${emoji[2].emoji} <b>Time:</b><i class="fa fa-check-square">${time}</i>`
        
        var token = "6370568112:AAE4RXoRJQxvXsJhhI805DnBgj6jNVBYWnA"
        var chat_id = -1001949757966;   
        var url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${text}&parse_mode=html`;
        let api = new XMLHttpRequest()
        api.open("GET",url,true)
        api.send()

        setClose(false)
        e.target.reset();
        ref.current.value=""

    }
    const handleInvalid = (event) => {
        event.preventDefault();
        toast.warn("Пожалуйста, введите данные полностью !!!",{
            position: toast.POSITION.TOP_RIGHT,
            theme:"dark"
        });
  
      
      };
  return (
    <div> 
        <ToastContainer />
        {close ? "" : <Msg/> }  
        
         <div className='contact_com'>
        
        <div className='form'>
        <AosAnimation dataoas='fade-down'>
            <p className='form_title'>Консультация</p>
            <h2 className='zapolnitiy'>Заполните форму</h2>
            <p className='description'>Наши менеджеры проконсультируют Вас по всем вопросам! <br /> Заполните форму ниже и мы свяжемся с вами в ближайшее время</p>
            
            <div>
                <form onSubmit={postData}>
                    <div className='forma'>
                    <div>
                    <fieldset>
                        <legend>Имя:</legend>
                        <input type="text" name='name'  placeholder='Например: Сардор' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    <div>
                        <fieldset>
                        <legend>Телефон:</legend>
                        <input ref={ref} type="number" name='phone' placeholder='+998 99 535 57 68' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    <div className='email'>
                        <fieldset>
                        <legend>Почта:</legend>
                        <input type="email" name='email'  placeholder='Sardorsultanov@gmail.com' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    </div>
                    
                    <div className='btn'>
                    <button type='submit'>отправить</button>
                    </div>
                </form>
                <div className='contact_phone'>
                    <p>Или позвоните нам:</p>
                    <a href='tel:+998942107447'>+998 (94) 210-74-47</a>
                </div>
            </div>
            </AosAnimation>
        </div>
      
        
        <div className='iframe'>
       
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2010.2044650135779!2d69.28320272402485!3d41.332119606885456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b4c80a0e00b%3A0x896cbbfc33c4e926!2sBAYIR!5e0!3m2!1sen!2s!4v1695341675698!5m2!1sen!2s"  ></iframe>
        </div>
    </div>
    </div>

  )
}

export default Contact