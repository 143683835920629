import { useEffect, useState } from "react";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import ResNavbar from './components/resnavbar'
import { ItemContext } from "./context/ItemContext";
import "./index.css"
import { routes } from "./routes";
import { Route, Routes } from "react-router-dom";
import Chat from "./components/chat";
import NotFound from "./components/notFound";
import Iconchat from "./assets/Image/main/icon.svg" 
import { BsFillChatDotsFill } from "react-icons/bs";
import Test from "./components/test";
import { useDispatch, useSelector } from "react-redux";
import { getProductFailure, getProductStart, getProductSuccess } from "./redux/slice/product.slice";
import AuthService from "./redux/service/product";
import Loader from "./components/loader/loader";
import Scrolltop from "./components/scrollTop/scrollTop";

function App() {
  const initialState = {
    name:"",
    phone:"",
    email:""
  }
  const dispatch = useDispatch()
  const {product,loading} = useSelector((state)=>state.productSlice)

  const [dropdown,setDropDown] = useState(true)
  const [state,setState] = useState([])
  const [tgchat,setTgchat] = useState(initialState)
  const [item,setItem] = useState()
  const [chat,setChat] = useState(false)
  const [close,setClose] = useState(true)
  const [url,setUrl] = useState(null)
  const [form,setForm] = useState(false)

  const getProductsData = async () =>{
    dispatch(getProductStart())
    try {
      const response = await AuthService.ProductService()
      dispatch(getProductSuccess(response))
    } catch (error) {
      dispatch(getProductFailure(error))
    }
  }

  useEffect(()=>{
    getProductsData()
  },[])

  return (
    <div className="App">
      {loading ? 
      <Loader/> 
      : 
      <ItemContext.Provider value={{dropdown,setDropDown,form,setForm,state,setState,setItem,item,close,setClose,url,setUrl,chat,setChat,tgchat,setTgchat}}>
      <div className="navbar-desktop">
      <Navbar/>
      </div>

      <div className="navbar-mobile">
      <ResNavbar/>
      </div>

      <div onMouseDown={()=>setDropDown(true)}>
      <Routes>
       { routes.map((item,key)=>(
          <Route path={item.url} key={key} element={item.page}/>
        ))}
        <Route path="*" element={<NotFound/>} />
      </Routes>
      </div>
      {chat ? <Chat close={chat}/>:  <div className="app-sms" onClick={()=>setChat(true)}>
               
                <span className="app-sms-icon"><img src={Iconchat} alt="Iconchat" /><span className="app-sms-number"></span></span>
      </div>}
      {/* <Test/> */}
      <Footer/>

       <Scrolltop/> 
      </ItemContext.Provider>
    }
    </div>
  );
}

export default App;
