import React, { useContext, useEffect, useState } from 'react'
import { Pagination } from 'antd';
import {CompleteMebels} from "../data.db"
import MebelTypeLink from "../mebelTypes"
import Loader from "../components/loader/loader"
import "../style/piece.css"
import "../style/main.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ItemContext } from '../context/ItemContext';
import { useSelector } from 'react-redux';
const Piece = ({checked=[]}) => {
  const {product,loading} = useSelector(state=>state.productSlice)
  const check = JSON.parse(localStorage.getItem('check'));
  const checkedAll = check === null? ['Штучно'] : [check]
  const [checkedMebel,setCheckedMebel] = useState([])
  const navigate = useNavigate()
  const {slug} = useParams()
  // mebel url check
  const handleCheckingUrl = () =>{
    const handleCheck = MebelTypeLink.find(c=> c.typeLinkUz === slug)
    if(!handleCheck?.typeLinkRu){
      navigate('/')
    }
    else{
      setCheckedMebel([handleCheck.typeLinkUz])
      filterData([handleCheck.typeLinkRu])
    }
  }
  // checkedMebel = url === undefined  ? [] : [MebelTypeLink.find(c=> c.typeLinkUz === slug).typeLinkRu] 
  const {setState,setItem} = useContext(ItemContext)
  const [pagination, setPagination] = useState({
    current: 1, 
    pageSize: 9, 
  });
  const [mebeltype, setMebelType] = useState(product);
  // const [data, setData] = useState(mebeltype);

      const filterData=(checkedMebel)=>{
        var updatedata = []
        if(checkedAll[0] === "Штучно"){
          
          if(checked.length !== 0){
            for(let i=0; i<checked.length; i++){
              for(let j=0;j<product.length;j++){
                if(checked[i]===(product[j].type === "" ? "" : product[j].type)){
                  updatedata.push(product[j]);
                  
                }
              }
            }
            setMebelType(updatedata)
            Mebel(updatedata,checkedMebel)
          }
          else{
            Mebel(product,checkedMebel)
          }
        }
        else{
          const newteamDataPiece = CompleteMebels
          setMebelType(newteamDataPiece)
          var updatedatas = []
          if(checked.length !== 0){
            for(let i=0; i<checked.length; i++){
              for(let j=0;j<newteamDataPiece.length;j++){
                if(checked[i]===(newteamDataPiece[j].type.length === 0 ? null : newteamDataPiece[j].type)){
                  updatedatas.push(newteamDataPiece[j]);
                  
                }
              }
            }
            setMebelType(newteamDataPiece)
            // setData(newteamDataPiece.slice((pagination.current-1)*10,(pagination.current-1)*10+pagination.pageSize))
            Mebel(newteamDataPiece,checkedMebel)
          }
          else{
            Mebel(newteamDataPiece,checkedMebel)
          }
        }
      }
      const Mebel =(filterMebel,checkedMebel)=>{
        var updatedata = []
        if(checkedAll[0] === "Комплект"){
          setPagination({
            current: 1, 
            pageSize: 9, 
          })
          updatedata = filterMebel
          setMebelType(updatedata)
        }
        else{
          if(checkedMebel.length !== 0){
            setPagination({
              current: 1, 
              pageSize: 9, 
            })
          for(let i=0; i<checkedMebel.length; i++){
            for(let j=0;j<filterMebel.length;j++){
              if(filterMebel[j].mebel.toLowerCase().search(checkedMebel[i].toLowerCase()) >= 0){
                updatedata.push(filterMebel[j]);
              }
            }
          }
          
        }
        }
        setMebelType(updatedata)
    }
   
      const dataPagination = ()=>{
          return mebeltype.slice((pagination.current-1)*pagination.pageSize,pagination.current * pagination.pageSize)
       
      }
      useEffect(() => {
          handleCheckingUrl()
          dataPagination()
         
      }, [slug,checked,check]);

      const navigateItem = (item) =>{
        setState(product.filter(c=>c.mebel === item.mebel))
        setItem(item)
        localStorage.setItem('items', JSON.stringify(item));
        item.mebel === "Комплект" ? navigate(`/complete-mebel/${item.slug}`) : navigate(`/mebel/${checkedMebel}/${item.name}`)
      }
  return (
    <div className='piece'>
    {loading ? 
      <Loader/> 
      : 
      <React.Fragment>
        <div className='piece-box' id={`${checkedAll[0] === "Комплект" ? "comp" : ""}`}>
          {dataPagination().length !== 0 ? 
            dataPagination().map(item=>(
            <div className='piece-box-item'>
                <div className='bg-img'>
                <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>navigateItem(item)}>Подробнее</p>
            </div>
                {item.sale  && <p className='sale'>{item?.sale===null ? "" : item?.sale + " %" }</p>}
                    <img src={item?.main_image} alt={item?.title} />
                </div>
                <div>
                    <div className='cirlce'>
                        {/* <span className='box-circle' id='blue'></span>
                        <span className='box-circle' id='white'></span>
                        <span className='box-circle' id=''></span> */}
                    </div>
                    <div className='piece-item-title'><p>{item.title.split('|')[0]}</p> <b>{item.name}</b></div>
                    <div className='const'>
                        <b id='old'><strike>{item.old && item.old}</strike></b>
                        <b id='new'>{item.new && item.new + " сум"}</b>
                    </div>
                </div>
            </div>
            )) : <p>No data</p>}
        </div>
        <div className='pagination'>
        <Pagination
      current={pagination.current}
      pageSize={pagination.pageSize}
      defaultCurrent={1}
      total={mebeltype.length} 
      onChange={(page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize });
      }}
    />
        </div>
</React.Fragment>
}
    </div>
  )
}

export default Piece