const MebelTypeLink = [
    {
        id:1,
        typeLinkRu:'Столы для руководителей',
        typeLinkUz:'stol-dlya-rukovoditeley'
    },
    {
        id:2,
        typeLinkRu:'Столы для сотрудников',
        typeLinkUz:'stol-dlya-sotrudnikov'
    },
    {
        id:3,
        typeLinkRu:'Столы для переговоров',
        typeLinkUz:'stol-dlya-peregovorov'
    },
    {
        id:4,
        typeLinkRu:'Open Space',
        typeLinkUz:'open-space'
    },
    {
        id:5,
        typeLinkRu:'журнальные столики',
        typeLinkUz:'jurnalniye-stoliki'
    },
    {
        id:6,
        typeLinkRu:'Ресепшн Стол',
        typeLinkUz:'resepshn-stol'
    },
    {
        id:7,
        typeLinkRu:'Модульная Мебель',
        typeLinkUz:'modulnaya-mebel'
    },
    {
        id:8,
        typeLinkRu:'Мягкая Мебель',
        typeLinkUz:'myagkaya-mebel'
    },

    {
        id:9,
        typeLinkRu:'Шкафы',
        typeLinkUz:'shkafi' 
    },
    {
        id:10,
        typeLinkRu:'Комоды',
        typeLinkUz:'komodi'
    },
    {
        id:11,
        typeLinkRu:'Кресла',
        typeLinkUz:'kresla'
    },
]

export default MebelTypeLink