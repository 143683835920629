import React from 'react'

//components
import ContactCom from '../../components/contact'
import AosAnimation from "../../components/AosAnimation"
//css
import "../../style/assembly.css"
//assets
import assambly_1 from "../../assets/Image/assembly/assambly_1.png"
import assambly_2 from "../../assets/Image/assembly/assambly_2.png"
import Seo from '../../components/seo'
const Assembly = () => {
  return (
    <div>
        <Seo title={'BAYIR | Сборка'} description={'СБОРКА И МОНТАЖ МЕБЕЛИ ОСУЩЕСТВЛЯЕТСЯ БЕСПЛАТНО!'}/>
        <div className='assembly'>
            <AosAnimation dataoas='fade-left'>
        <div className='text_image'>
            <div>
                <h3>Сборка</h3>
                <h5>Сборка и монтаж мебели осуществляется бесплатно!</h5>
                <p>Служба доставки нашей компании работает с понедельника по пятницу, с 9-00 до 18-00. Внешний вид и долговечность приобретенной Вами мебели напрямую зависят от ее сборки. Наши специалисты имеют большой профессиональный опыт и соберут Вашу мебель быстро и качественно. Наши мастера несут полную ответственность за качество сборки и установку.</p>
            </div>
            <div>
                <img src={assambly_1} alt="assambly_1" />
            </div>
        </div>
        </AosAnimation>
    
        <AosAnimation dataoas='fade-right'>
        <div className='image_text'>
            <div>
                <img src={assambly_2} alt="assambly_2" />
            </div>
            <div className='row'>
                <h5>Важно знать перед монтажом мебели!</h5>
                <p>Для быстрой и качественной сборки, покупателю нужно освободить помещение в котором будет проводиться монтаж мебели, а также проемы и проходы через которые, будет осуществляться занос и разгрузка мебели.</p>
            </div>
        </div>
        </AosAnimation>
        </div>
        <ContactCom/>
    </div>
  )
}

export default Assembly