import React from 'react'
import "../../style/reviews.css"

//images
import review_img from "../../assets/Image/main/reviews.png"
import partner_1 from "../../assets/Image/partner/partlogo/part_1.png"


const Reviews = () => {


  return (
    <div className='reviews'>
        <div className="reviews-text">
            <div className="reviews-text-title">Только настоящие отзывы</div>
            <div className='reviews-comment'>
              <div className='comment-img'>
                <img src={partner_1} alt="partner_1" />
              </div>
              <div className='reviews-text-comment'>Крайне доволен опытом сотрудничества с мебельной компанией! Их профессионализм и качество продукции оставили впечатление. Заказывал у них мебель для офиса, и результат превзошел все ожидания. Дизайн был точно воплощен в реальность, каждая деталь продумана до мелочей. К тому же, процесс общения с сотрудниками компании был чрезвычайно приятным – оперативно отвечали на вопросы и учли все наши пожелания. Мебель пришла в срок, была аккуратно упакована и без повреждений. Рад, что выбрал именно эту компанию и с уверенностью могу рекомендовать ее всем, кто ищет надежного партнера</div>
            </div>
        </div>
        <div className="reviews-img">
          <img src={review_img} alt="review_img" />
        </div>
    </div>
  )
}

export default Reviews