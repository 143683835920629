import React, { useEffect, useState } from 'react'
import team from "../../assets/Image/about/team.png"
import team1 from "../../assets/Image/about/team2.JPG"
import team2 from "../../assets/Image/about/team3.JPG"
import "../../style/team.css"
import { useParams } from 'react-router-dom'
const Team = () => {
    const {slug} = useParams();
    const [data,setData]= useState(null)
    const teamData=[
        {
          id:1,
          fullname:"Нодир Султанов",
          position:"Директор",
          image:team,
        },
        {
          id:2,
          fullname:"Наталья Акопян",
          position:"unposition",
          image:team1,
        },
        {
          id:3,
          fullname:"Саидкомол Музаффаров",
          position:"unposition",
          image:team2,
        },
        {
          id:4,
          fullname:"Нодир Султанов",
          position:"Директор",
          image:team,
        },
        {
          id:5,
          fullname:"Нодир Султанов",
          position:"Директор",
          image:team,
        },
      ]
    useEffect(()=>{
        const filterData = teamData.find(c=>c.id === +slug) 
        setData(filterData)
    },[])
  return (
    <div className='team'>
        <div className='box-team'>
            <div className='team-title'>
                <h3>О Сотуднике</h3>
                <p>Lorem ipsum dolor sit amet consectetur. Dignissim pulvinar volutpat dictum et tempor. Interdum tincidunt sit arcu volutpat leo dictum pulvinar id malesuada. Sed mi aliquam cursus risus sed at. Nulla aliquet mi blandit morbi duis. Non at amet eget enim orci auctor magna adipiscing in. Volutpat pellentesque a ultrices amet quis sollicitudin sed. Id in non enim at commodo sed est eget commodo. Faucibus consectetur aliquam aliquet arcu ut quam quam vitae nibh. <br /><br />
                     In faucibus libero sit eu posuere in viverra et. Mauris velit sed senectus nec habitasse. Consequat scelerisque lectus viverra et purus laoreet. Id dolor pharetra pulvinar blandit donec viverra blandit urna tortor. Ut at sollicitudin arcu vitae. Lobortis tincidunt massa sit ut in vitae ultrices. Libero ultricies placerat blandit libero lorem. Tristique dui eget lectus bibendum adipiscing vitae. Mattis eget mi enim nibh nulla in eu amet. Leo scelerisque tellus neque nunc sit dapibus in. Morbi aliquam sagittis lorem a aliquam tincidunt.</p>
            </div>
            <div className='team-img'><img src={data?.image} alt="team" /></div>
        </div>
        <div className='box-team'>
            <div className='team-img' id='hide-img'><img src={data?.image} alt="team" /></div>
            <div lassName='team-title'>
                <h3 id='hide-img'>О Сотуднике</h3>
                <p>Lorem ipsum dolor sit amet consectetur. Dignissim pulvinar volutpat dictum et tempor. Interdum tincidunt sit arcu volutpat leo dictum pulvinar id malesuada. Sed mi aliquam cursus risus sed at. Nulla aliquet mi blandit morbi duis. Non at amet eget enim orci auctor magna adipiscing in. Volutpat pellentesque a ultrices amet quis sollicitudin sed. Id in non enim at commodo sed est eget commodo. Faucibus consectetur aliquam aliquet arcu ut quam quam vitae nibh. <br /><br />
                     In faucibus libero sit eu posuere in viverra et. Mauris velit sed senectus nec habitasse. Consequat scelerisque lectus viverra et purus laoreet. Id dolor pharetra pulvinar blandit donec viverra blandit urna tortor. Ut at sollicitudin arcu vitae. Lobortis tincidunt massa sit ut in vitae ultrices. Libero ultricies placerat blandit libero lorem. Tristique dui eget lectus bibendum adipiscing vitae. Mattis eget mi enim nibh nulla in eu amet. Leo scelerisque tellus neque nunc sit dapibus in. Morbi aliquam sagittis lorem a aliquam tincidunt.</p>
            </div>
            <div className='team-img' id='hide-img-r'><img src={data?.image} alt="team" /></div>

        </div>
    </div>
  )
}

export default Team