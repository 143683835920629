import React, { useContext, useEffect, useState } from 'react'
import { Pagination } from 'antd';
import {teamDataPiece} from "../data.db"
import "../style/piece.css"
import "../style/main.css"
import { useNavigate, useParams } from 'react-router-dom';
import { ItemContext } from '../context/ItemContext';

const Piece = ({checked=[],checkedAll=["Штучно"],checkedMebel=[]}) => {
  const navigate = useNavigate()
  const {slug} = useParams()
  // let url = slug
  // checkedMebel = url === undefined  ? [] : [url] 
  // url = undefined
  const {setState,setItem} = useContext(ItemContext)
  const [pagination, setPagination] = useState({
    current: 1, 
    pageSize: 9, 
  });
  const [mebeltype, setMebelType] = useState(teamDataPiece);
  const [data, setData] = useState(mebeltype);
 
      const filterData=()=>{
        var updatedata = []
        if(checkedAll[0] === "Штучно"){
          if(checked.length !== 0){
            for(let i=0; i<checked.length; i++){
              for(let j=0;j<teamDataPiece.length;j++){
                if(checked[i]===teamDataPiece[j].type){
                  updatedata.push(teamDataPiece[j]);
                  
                }
              }
            }
            setMebelType(updatedata)
            Mebel(updatedata)
          }
          else{
            Mebel(teamDataPiece)
          }
        }
        else{
          var updatedatas = []
          if(checked.length !== 0){
            for(let i=0; i<checked.length; i++){
              for(let j=0;j<teamDataPiece.length;j++){
                if(checked[i]===teamDataPiece[j].type){
                  updatedatas.push(teamDataPiece[j]);
                  
                }
              }
            }
            setMebelType(updatedatas)
            setData(mebeltype.slice((pagination.current-1)*10,(pagination.current-1)*10+pagination.pageSize))
            Mebel(updatedatas)
          }
          else{
            Mebel(teamDataPiece)
          }
        }
      }
      const Mebel =(datamebel)=>{
        if(checkedMebel.length !== 0){
        var updatedata = []
        for(let i=0; i<checkedMebel.length; i++){
          for(let j=0;j<datamebel.length;j++){
            if(checkedMebel[i]===datamebel[j].mebel){
              updatedata.push(datamebel[j]);
            }
          }
        }
        setMebelType(updatedata)
        
      }
    }
   
      const dataPagination = ()=>{
        return mebeltype.slice((pagination.current-1)*10,(pagination.current-1)*10+pagination.pageSize)
      }
      useEffect(() => {
          filterData()
          dataPagination()
         
      }, [pagination,checked,checkedMebel,checkedAll]);

      const navigateItem = (item) =>{
        setState(teamDataPiece.filter(c=>c.mebel === item.mebel))
        setItem(item)
        navigate(`/mebel/info/${item.id}`)
      }
      
  return (
    <div className='piece'>
        <div className='piece-box'>
          {dataPagination().length !== 0 ? 
            dataPagination().map(item=>(
            <div className='piece-box-item'>
                <div className='bg-img'>
                <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>navigateItem(item)}>Подробнее</p>
            </div>
                {item.sale  && <p className='sale'>{item.sale===null ? "" : item.sale + " %" }</p>}
                    <img src={item.img} alt={item.title} />
                </div>
                <div>
                    <div className='cirlce'>
                        {/* <span className='box-circle' id='blue'></span>
                        <span className='box-circle' id='white'></span>
                        <span className='box-circle' id=''></span> */}
                    </div>
                    <div className='piece-item-title'>{item.title.split('|')[0]} <b>{item.name}</b></div>
                    <div className='const'>
                        <b id='old'><strike>{item.old && item.old}</strike></b>
                        <b id='new'>{item.new && item.new + " сум"}</b>
                    </div>
                </div>
            </div>
            )) : <p>No data</p>}
        </div>
        <div className='pagination'>
        <Pagination
      current={pagination.current}
      pageSize={pagination.pageSize}
      defaultCurrent={1}
      total={mebeltype.length} 
      onChange={(page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize });
      }}
    />
        </div>
    </div>
  )
}

export default Piece