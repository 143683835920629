import React from 'react'
import complete_1 from "./../assets/Image/complete/complete_1.JPG"
import complete_2 from "./../assets/Image/complete/complete_2.JPG"
import complete_3 from "./../assets/Image/complete/complete_3.jpeg"
import complete_4 from "./../assets/Image/complete/complete_4.JPG"
import complete_5 from "./../assets/Image/complete/complete_5.JPG"
import complete_6 from "./../assets/Image/complete/complete_6.JPG"
import complete_7 from "./../assets/Image/complete/complete_7.jpeg"
import { useNavigate, useParams } from 'react-router-dom'

import "../style/complete.css"
const Complete = () => {
    const {slug}  = useParams()
    const navigate = useNavigate()
  const Imgdata = [
    {
      id:1,
      img:complete_1,
    },
    {
      id:2,
      img:complete_7,
    },
    {
      id:3,
      img:complete_2,
    },
    {
      id:4,
      img:complete_2,
    },
    {
      id:5,
      img:complete_3,
    },
    {
      id:6,
      img:complete_4,
    },
    {
      id:7,
      img:complete_5,
    },
    {
      id:8,
      img:complete_6,
    },
    {
      id:9,
      img:complete_6,
    },
  ]

  const filterData = Imgdata.find(c=>c.id === Number(slug))
  
  return (
    <div className='complete'>
      {filterData ? 
        <img src={filterData.img} key={filterData.id} alt="img-compete" className='complete-img' />
        : navigate('/')}
    </div>
  )
}

export default Complete