import React from 'react'
import Complete from '../../components/complete'
import CompleteForm from '../../components/completeform'
import Contactcom from "../../components/contact"
import MainSwiper from '../../components/mainSwiper'
const Completemebel = () => {
 
  return (
    <div>
      <div  style={{padding:"20px"}}>
        <Complete/>
      </div>
        <CompleteForm/>
        <br />
        <Contactcom/>
    </div>
  )
}

export default Completemebel