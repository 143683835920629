import React from 'react'

//styles
import "../../style/scrolltop.css"

// icons
import { FaUpLong } from "react-icons/fa6";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";

const Scrolltop = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
  return (
    <div className='gotopbtn' onClick={scrollToTop}>
        <span><FaArrowAltCircleUp /></span>
    </div>
  )
}

export default Scrolltop