import React, { useState } from 'react'
import {dataPartner} from "../../data.db"

import "../../style/partner.css"
import AosAnimation from "../../components/AosAnimation"
import Seo from '../../components/seo'
const Partners = () => {

  const changeImg = (id) =>{
    setImg(id)
  }
  const [img,setImg] = useState(null)
  return (
    <div className='partners'>
      <Seo title={'BAYIR ┃ Нам доверяют'} description={'НАШИ ЗАРУБЕЖНЫЕ И МЕСТНЫЕ ПАРТНЕРЫ'}/>
      <h1>НАШИ ЗАРУБЕЖНЫЕ И МЕСТНЫЕ ПАРТНЕРЫ</h1>
    <div className='partners-box'>
      {dataPartner.map(item=>(
          <AosAnimation dataoas='fade-up'>
          <div style={{ backgroundImage: `url(${img === item.id ? item.img2 : item.img1})`}} onMouseOver={()=>changeImg(item.id)} onMouseLeave={()=>setImg(0)} className='partners-item'>

          </div>
          </AosAnimation>
      ))}
    </div>
    </div>
  )
}

export default Partners