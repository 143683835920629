import React, { useContext, useRef } from 'react'

// style
import { ToastContainer, toast } from 'react-toastify';
import "../../style/thanks.css"
import { ItemContext } from '../../context/ItemContext'
import { AiOutlineClose } from 'react-icons/ai'
const Thanks = () => {
    const ref = useRef(null);

    const handleInvalid = (event) => {
        event.preventDefault(); // Prevent default error message
        toast.warn("Пожалуйста, введите данные полностью !!!",{
            position: toast.POSITION.TOP_RIGHT,
            theme:"dark"
            
        });
  
      
      };
      const handleChange =(e)=>{
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        const name = data.get('name')
        const email = data.get('email')
        const phone = data.get('phone')
        const date = new Date()
        const day = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
        const time = `${date.getHours()}:${date.getMinutes()}`
        
      const  emoji = [
            {
              emoji: "✅",
              description: "check mark button",
              category: "Symbols",
              aliases: ["white_check_mark"],
              tags: [],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "📞",
              description: "telephone receiver",
              category: "Objects",
              aliases: ["telephone_receiver"],
              tags: ["phone", "call"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "⏰",
              description: "alarm clock",
              category: "Travel & Places",
              aliases: ["alarm_clock"],
              tags: ["morning"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "👤",
              description: "bust in silhouette",
              category: "People & Body",
              aliases: ["bust_in_silhouette"],
              tags: ["user"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "💬",
              description: "speech balloon",
              category: "Smileys & Emotion",
              aliases: ["speech_balloon"],
              tags: ["comment"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
            {
              emoji: "📆",
              description: "tear-off calendar",
              category: "Objects",
              aliases: ["calendar"],
              tags: ["schedule"],
              unicode_version: "6.0",
              ios_version: "6.0",
            },
          ];
          var text = `%0A ${emoji[3].emoji} <b>Name:</b><i class="fa fa-check-square">${name}</i>%0A ${emoji[1].emoji} <b>Number:</b><i class="fa fa-check-square">${phone}</i>%0A ${emoji[4].emoji} <b>Email:</b><i class="fa fa-check-square">${email}</i>%0A ${emoji[5].emoji} <b>Date:</b><i class="fa fa-check-square">${day}</i> %0A ${emoji[2].emoji} <b>Time:</b><i class="fa fa-check-square">${time}</i>`
        
        var token = "6370568112:AAE4RXoRJQxvXsJhhI805DnBgj6jNVBYWnA"
        var chat_id = -1001949757966;   
        var url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${text}&parse_mode=html`;
        let api = new XMLHttpRequest()
        api.open("GET",url,true)
        api.send()
          setForm(false)
        setClose(false)
        e.target.reset();
        ref.current.value=""

      }
    const {close,setClose,setForm} = useContext(ItemContext)
  return (
    <div className='thanks'>
              <ToastContainer />
    <div className='contact_com'>
        
        <div className='formm'>
            <p className='formm-icon' onClick={()=>setForm(false)}><AiOutlineClose/></p>
            <p className='form_title'>Консультация</p>
            <h2 className='zapolnitiy'>Оставить заявку</h2>
            <p className='description'>Наши менеджеры проконсультируют Вас по всем вопросам! <br /> Заполните форму ниже и мы свяжемся с вами в ближайшее время</p>
            
            <div>
                <form onSubmit={handleChange}>
                    <div className='forma'>
                    <div>
                    <fieldset>
                        <legend>Имя:</legend>
                        <input type="text" name='name' placeholder='Например: Сардор' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    <div>
                        <fieldset>
                        <legend>Телефон:</legend>
                        <input type="number" name='phone' ref={ref} placeholder='+998 99 535 57 68' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    <div className='email'>
                        <fieldset>
                        <legend>Почта:</legend>
                        <input type="email" name='email' placeholder='Sardorsultanov@gmail.com' required onInvalid={handleInvalid}/>
                    </fieldset>
                    </div>
                    </div>
                    
                    <div className='btn'>
                    <button>отправить</button>
                    </div>
                </form>
                <div className='contact_phone'>
                    <p>Или позвоните нам:</p>
                    <h3>+998 (90) 051-80-90</h3>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Thanks