import Kres1 from "./assets/Image/mebel/kreslo1.png";
import Kres2 from "./assets/Image/mebel/kreslo2.png";
import Kres3 from "./assets/Image/mebel/kreslo3.png";
import Kres4 from "./assets/Image/mebel/kresla_4.png";
import Kres5 from "./assets/Image/mebel/kresla_5.png";
import Kres6 from "./assets/Image/mebel/kresla_6.png";
import Kres7 from "./assets/Image/mebel/kresla_7.png";
import Kres8 from "./assets/Image/mebel/kresla_8.png";
import Kres9 from "./assets/Image/mebel/kresla_9.png";
import Kres10 from "./assets/Image/mebel/kresla_10.png";
import Kres11 from "./assets/Image/mebel/kresla_11.png";
import Kres12 from "./assets/Image/mebel/kresla_12.png";
import Kres13 from "./assets/Image/mebel/kresla_13.png";
import Kres14 from "./assets/Image/mebel/kresla_14.png";
import Kres15 from "./assets/Image/mebel/kresla_15.png";
import Kres16 from "./assets/Image/mebel/kresla_16.png";
import Kres17 from "./assets/Image/mebel/kresla_17.png";

import sdr_1 from "./assets/Image/mebel/sdr_1.png";
import sdr_2 from "./assets/Image/mebel/sdr_2.png";
import sdr_3 from "./assets/Image/mebel/sdr_3.png";
import sdr_4 from "./assets/Image/mebel/sdr_4.png";
import sdr_5 from "./assets/Image/mebel/sdr_5.png";
import sdr_6 from "./assets/Image/mebel/sdr_6.png";
import sdr_7 from "./assets/Image/mebel/sdr_7.png";
import sdr_8 from "./assets/Image/mebel/sdr_8.png";

import sdm_1 from "./assets/Image/mebel/sdm_1.png";
import sdm_2 from "./assets/Image/mebel/sdm_2.png";
import sdm_3 from "./assets/Image/mebel/sdm_3.png";
import sdm_4 from "./assets/Image/mebel/sdm_4.png";

import shkaf_1 from "./assets/Image/mebel/shkaf_1.png";
import shkaf_2 from "./assets/Image/mebel/shkaf_2.png";
import shkaf_3 from "./assets/Image/mebel/shkaf_3.png";
import shkaf_4 from "./assets/Image/mebel/shkaf_4.png";
import shkaf_5 from "./assets/Image/mebel/shkaf_5.png";
import shkaf_6 from "./assets/Image/mebel/shkaf_6.png";
import shkaf_7 from "./assets/Image/mebel/shkaf_7.png";
import shkaf_8 from "./assets/Image/mebel/shkaf_8.png";
import shkaf_9 from "./assets/Image/mebel/shkaf_9.png";
import shkaf_10 from "./assets/Image/mebel/shkaf_10.png";
import shkaf_11 from "./assets/Image/mebel/shkaf_11.png";
import shkaf_12 from "./assets/Image/mebel/shkaf_12.png";
import shkaf_13 from "./assets/Image/mebel/shkaf_13.png";
import shkaf_14 from "./assets/Image/mebel/shkaf_14.png";
import shkaf_15 from "./assets/Image/mebel/shkaf_15.png";
import shkaf_16 from "./assets/Image/mebel/shkaf_16.png";
import shkaf_17 from "./assets/Image/mebel/shkaf_17.png";

import komodi_1 from "./assets/Image/mebel/komodi_1.png";
import komodi_2 from "./assets/Image/mebel/komodi_2.png";
import komodi_3 from "./assets/Image/mebel/komodi_3.png";
import komodi_4 from "./assets/Image/mebel/komodi_4.png";
import komodi_5 from "./assets/Image/mebel/komodi_5.png";
import komodi_6 from "./assets/Image/mebel/komodi_6.png";
import komodi_7 from "./assets/Image/mebel/komodi_7.png";
import komodi_8 from "./assets/Image/mebel/komodi_8.png";
import komodi_9 from "./assets/Image/mebel/komodi_9.png";
import komodi_10 from "./assets/Image/mebel/komodi_10.png";

import journal_1 from "./assets/Image/mebel/journal_1.png";
import journal_2 from "./assets/Image/mebel/journal_2.png";
import journal_3 from "./assets/Image/mebel/journal_3.png";
import journal_4 from "./assets/Image/mebel/journal_4.png";

import konferens_1 from "./assets/Image/mebel/konferens_1.png";
import konferens_2 from "./assets/Image/mebel/konferens_2.png";
import konferens_3 from "./assets/Image/mebel/konferens_3.png";
import konferens_4 from "./assets/Image/mebel/konferens_4.png";
import konferens_5 from "./assets/Image/mebel/konferens_5.png";

import register_1_1 from "./assets/Image/mebel/register_1_1.png";
import register_1_2 from "./assets/Image/mebel/register_1_2.png";
import register_1_3 from "./assets/Image/mebel/register_1_3.png";
import register_2_1 from "./assets/Image/mebel/register_2_1.png";
import register_2_2 from "./assets/Image/mebel/register_2_2.png";
import register_3_1 from "./assets/Image/mebel/register_3_1.png";
import register_3_2 from "./assets/Image/mebel/register_3_2.png";
import register_4_1 from "./assets/Image/mebel/register_4_1.png";

import one_space_1 from "./assets/Image/mebel/one_space_1.png";
import one_space_2 from "./assets/Image/mebel/one_space_2.png";
import one_space_3 from "./assets/Image/mebel/one_space_3.png";
import one_space_4 from "./assets/Image/mebel/one_space_4.png";
import one_space_5 from "./assets/Image/mebel/one_space_5.png";

import img_75 from "./assets/Image/mebel/img/75.png";
import img_76 from "./assets/Image/mebel/img/76.png";
import img_77 from "./assets/Image/mebel/img/77.png";
import img_78 from "./assets/Image/mebel/img/78.png";
import img_79 from "./assets/Image/mebel/img/79.jpg";
import img_80 from "./assets/Image/mebel/img/80.png";
import img_81 from "./assets/Image/mebel/img/81.jpg";
import img_82 from "./assets/Image/mebel/img/82.jpg";
import img_83 from "./assets/Image/mebel/img/83.jpg";
import img_84 from "./assets/Image/mebel/img/84.jpg";
import img_85 from "./assets/Image/mebel/img/85.JPG";
import img_86 from "./assets/Image/mebel/img/86.jpg";
import img_87 from "./assets/Image/mebel/img/87.png";
import img_88 from "./assets/Image/mebel/img/88.png";
import img_89 from "./assets/Image/mebel/img/89.png";
import img_90 from "./assets/Image/mebel/img/90.png";
import img_91 from "./assets/Image/mebel/img/91.png";
import img_92 from "./assets/Image/mebel/img/92.png";
import img_93 from "./assets/Image/mebel/img/93.png";
import img_94 from "./assets/Image/mebel/img/94.png";
import img_95 from "./assets/Image/mebel/img/95.JPG";
import img_96 from "./assets/Image/mebel/img/96.JPG";
import img_97 from "./assets/Image/mebel/img/97.JPG";
import img_98 from "./assets/Image/mebel/img/98.JPG";
import img_99 from "./assets/Image/mebel/img/99.png";
import img_100 from "./assets/Image/mebel/img/100.JPG";
import img_101 from "./assets/Image/mebel/img/101.JPG";
import img_102 from "./assets/Image/mebel/img/102.JPG";
import img_103 from "./assets/Image/mebel/img/103.png";
import img_104 from "./assets/Image/mebel/img/104.png";
import img_105 from "./assets/Image/mebel/img/105.png";
import img_106 from "./assets/Image/mebel/img/106.png";
import img_107 from "./assets/Image/mebel/img/107.png";
import img_108 from "./assets/Image/mebel/img/108.png";
import img_109 from "./assets/Image/mebel/img/109.png";
import img_110 from "./assets/Image/mebel/img/110.png";
import img_111 from "./assets/Image/mebel/img/111.JPG";
import img_112 from "./assets/Image/mebel/img/112.jpg";
import img_114 from "./assets/Image/mebel/img/114.JPG";
import img_113 from "./assets/Image/mebel/img/113.JPG";
// import img_114 from "./assets/Image/mebel/img/114.png";
import img_115 from "./assets/Image/mebel/img/115.jpg";
import img_116 from "./assets/Image/mebel/img/116.jpg";
import img_117 from "./assets/Image/mebel/img/117.png";
import img_118 from "./assets/Image/mebel/img/118.png";
import img_119 from "./assets/Image/mebel/img/119.png";
import img_120 from "./assets/Image/mebel/img/120.png";
import img_121 from "./assets/Image/mebel/img/121.png";
import img_122 from "./assets/Image/mebel/img/122.png";
import img_123 from "./assets/Image/mebel/img/123.png";
import img_124 from "./assets/Image/mebel/img/124.png";
import img_125 from "./assets/Image/mebel/img/125.png";
import img_126 from "./assets/Image/mebel/img/126.png";
import img_127 from "./assets/Image/mebel/img/127.png";
import img_128 from "./assets/Image/mebel/img/128.png";
import img_129 from "./assets/Image/mebel/img/129.png";
import img_130 from "./assets/Image/mebel/img/130.png";
import img_131 from "./assets/Image/mebel/img/131.png";
import img_132 from "./assets/Image/mebel/img/132.png";
import img_133 from "./assets/Image/mebel/img/133.png";
import img_134 from "./assets/Image/mebel/img/134.png";
import img_135 from "./assets/Image/mebel/img/135.png";
import img_136 from "./assets/Image/mebel/img/136.png";
import img_137 from "./assets/Image/mebel/img/137.png";
import img_138 from "./assets/Image/mebel/img/138.png";
import img_139 from "./assets/Image/mebel/img/139.png";
import img_140 from "./assets/Image/mebel/img/140.png";
import img_141 from "./assets/Image/mebel/img/141.png";
import img_142 from "./assets/Image/mebel/img/142.png";
import img_143 from "./assets/Image/mebel/img/143.png";
import img_144 from "./assets/Image/mebel/img/144.png";
import img_145 from "./assets/Image/mebel/img/145.png";
import img_146 from "./assets/Image/mebel/img/146.png";
import img_147 from "./assets/Image/mebel/img/147.png";
import img_148 from "./assets/Image/mebel/img/148.png";
import img_149 from "./assets/Image/mebel/img/149.png";
import img_150 from "./assets/Image/mebel/img/150.png";
import img_151 from "./assets/Image/mebel/img/151.png";
import img_152 from "./assets/Image/mebel/img/152.png";
import img_153 from "./assets/Image/mebel/img/153.png";
import img_154 from "./assets/Image/mebel/img/154.png";
import img_155 from "./assets/Image/mebel/img/155.png";
import img_156 from "./assets/Image/mebel/img/156.png";
import img_157 from "./assets/Image/mebel/img/157.png";
import img_158 from "./assets/Image/mebel/img/158.png";
import img_159 from "./assets/Image/mebel/img/159.png";
import img_160 from "./assets/Image/mebel/img/160.png";
import img_161 from "./assets/Image/mebel/img/161.png";
import img_162 from "./assets/Image/mebel/img/162.png";
import img_163 from "./assets/Image/mebel/img/163.png";
import img_164 from "./assets/Image/mebel/img/164.png";
import img_165 from "./assets/Image/mebel/img/165.png";
import img_166 from "./assets/Image/mebel/img/166.png";
import img_167 from "./assets/Image/mebel/img/167.png";
import img_168 from "./assets/Image/mebel/img/168.png";
import img_169 from "./assets/Image/mebel/img/169.png";
import img_170 from "./assets/Image/mebel/img/170.png";
import img_171 from "./assets/Image/mebel/img/171.png";
import img_172 from "./assets/Image/mebel/img/172.png";
import img_173 from "./assets/Image/mebel/img/173.png";
import img_174 from "./assets/Image/mebel/img/174.png";
import img_175 from "./assets/Image/mebel/img/175.jpeg";
import img_176 from "./assets/Image/mebel/img/176.jpg";

//compelete
import complete_1 from "./assets/Image/complete/complete_1.JPG"
import complete_2 from "./assets/Image/complete/complete_2.JPG"
import complete_3 from "./assets/Image/complete/complete_3.jpeg"
import complete_4 from "./assets/Image/complete/complete_4.JPG"
import complete_5 from "./assets/Image/complete/complete_5.JPG"
import complete_6 from "./assets/Image/complete/complete_6.JPG"
import complete_7 from "./assets/Image/complete/complete_7.jpeg"


//partner

import part_1 from "./assets/Image/partner/partlogo/part_1.png"
import part_2 from "./assets/Image/partner/partlogo/part_2.png"
import part_3 from "./assets/Image/partner/partlogo/part_1_3.png"
import part_4 from "./assets/Image/partner/partlogo/part_4.png"
import part_5 from "./assets/Image/partner/partlogo/part_1_5.png"
import part_6 from "./assets/Image/partner/partlogo/part_6.png"
import part_7 from "./assets/Image/partner/partlogo/part_1_7.png"
import part_8 from "./assets/Image/partner/partlogo/part_8.png"
import part_9 from "./assets/Image/partner/partlogo/part_1_9.png"
import part_10 from "./assets/Image/partner/partlogo/part_10.png"
import part_11 from "./assets/Image/partner/partlogo/part_1_11.png"
import part_12 from "./assets/Image/partner/partlogo/part_12.png"
import part_13 from "./assets/Image/partner/partlogo/part_1_13.png"
import part_14 from "./assets/Image/partner/partlogo/part_14.png"
import part_15 from "./assets/Image/partner/partlogo/part_1_15.png"
import part_16 from "./assets/Image/partner/partlogo/part_16.png"
import part_17 from "./assets/Image/partner/partlogo/part_1_17.png"
import part_18 from "./assets/Image/partner/partlogo/part_18.png"
import part_19 from "./assets/Image/partner/partlogo/part_1_19.png"
import part_20 from "./assets/Image/partner/partlogo/part_20.png"
import part_21 from "./assets/Image/partner/partlogo/part_1_21.png"
import part_22 from "./assets/Image/partner/partlogo/part_22.png"
import part_23 from "./assets/Image/partner/partlogo/part_1_23.png"
import part_24 from "./assets/Image/partner/partlogo/part_24.png"
import part_25 from "./assets/Image/partner/partlogo/part_1_25.png"
import part_26 from "./assets/Image/partner/partlogo/part_26.png"
import part_27 from "./assets/Image/partner/partlogo/part_1_27.png"
import part_28 from "./assets/Image/partner/partlogo/part_28.png"


const dataPartner = [
  {
    id:1,
    slug:"/",
    img1:part_2,
    img2:part_1,
  },
  {
    id:2,
    slug:"/",
    img1:part_4,
    img2:part_3,
  },
  {
    id:3,
    slug:"/",
    img1:part_6,
    img2:part_5,
  },
  {
    id:4,
    slug:"/",
    img1:part_8,
    img2:part_7,
  },
  {
    id:5,
    slug:"/",
    img1:part_10,
    img2:part_9,
  },
  {
    id:6,
    slug:"/",
    img1:part_12,
    img2:part_11,
  },
  {
    id:7,
    slug:"/",
    img1:part_18,
    img2:part_17,
  },
  {
    id:8,
    slug:"/",
    img1:part_14,
    img2:part_13,
  },
  {
    id:9,
    slug:"/",
    img1:part_16,
    img2:part_15,
  },
  {
    id:10,
    slug:"/",
    img1:part_20,
    img2:part_19,
  },
  {
    id:11,
    slug:"/",
    img1:part_22,
    img2:part_21,
  },
  {
    id:12,
    slug:"/",
    img1:part_24,
    img2:part_23,
  },
  {
    id:13,
    slug:"/",
    img1:part_26,
    img2:part_25,
  },
  {
    id:14,
    slug:"/",
    img1:part_28,
    img2:part_27,
  },
]

const teamDataPiece2 = [
  // {
  //   id:1,
  //   title:"Офисное кресло",
  //   old:"350 000",
  //   new:"250 000",
  //   name:null,
  //   type:"МДФ",
  //   mebel:"Кресла",
  //   sale:null,
  //   img_list:[Kres1,Kres1,Kres1,Kres1],
  //   img:Kres1,
  //   size:null,
  //   subtitle:"Кресло премиум-класса",
  //   description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  // },
  // {
  //     id:2,
  //     title:"Офисное кресло",
  //     old:"350 000",
  //     new:"250 000",
  //     type:"МДФ",
  //     mebel:"Кресла",
  //     name:null,
  //     sale:null,
  //     img_list:[Kres2,Kres2,Kres2,Kres2],
  //     size:null,
  //     img:Kres2,
  //     subtitle:"Кресло премиум-класса",
  //     description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  //   },
  //   {
  //     id:3,
  //     title:"Офисное кресло",
  //     old:"350 000",
  //     new:"250 000",
  //     type:"МДФ",
  //     mebel:"Кресла",
  //     name:null,
  //     sale:null,
  //     img_list:[Kres3,Kres3,Kres3,Kres3],
  //     size:null,
  //     img:Kres3,
  //     subtitle:"Кресло премиум-класса",
  //     description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  //   },

  {
    id: 4,
    title: "Столы для руководителей",
    old: null,
    new: "11 650 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    name: "B-2251",
    sale: null,
    img_list: [sdr_1, sdr_1, sdr_1, sdr_1],
    size: "2200W*1050D*760H",
    img: sdr_1,
    subtitle: "Столы для руководителей",
    description:
      "Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай",
  },
  {
    id: 5,
    title: "Столы для руководителей",
    old: null,
    new: "11 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_2, sdr_2, sdr_2, sdr_2],
    img: sdr_2,
    size: "2200W*1050D*760H",
    name: "B-2262",
    subtitle: "Столы для руководителей",
    description:
      "МДФ, облицовка шпон. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай",
  },
  {
    id: 6,
    title: "Столы для руководителей",
    old: null,
    new: "8 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_3, sdr_3, sdr_3, sdr_3],
    img: sdr_3,
    size: "1800W*900D*760H Байир Мастер",
    name: "B-1862",
    subtitle: "Столы для руководителей",
    description:
      "Стол из МДФ, облицовка шпон. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  },
  // {
  //   id: 7,
  //   title: "Столы для руководителей",
  //   old: null,
  //   new: "9 900 000",
  //   type: "МДФ",
  //   mebel: "Столы для руководителей",
  //   sale: null,
  //   img_list: [sdr_4, sdr_4, sdr_4, sdr_4],
  //   img: sdr_4,
  //   size: "2000W*950D*760H",
  //   name: "B-2065",
  //   subtitle: "Столы для руководителей",
  //   description:
  //     "Стол из МДФ. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  // },
];
const teamDataPiece = [
  // {
  //   id:1,
  //   title:"Офисное кресло",
  //   old:"350 000",
  //   new:"250 000",
  //   name:null,
  //   type:"МДФ",
  //   mebel:"Кресла",
  //   sale:null,
  //   img_list:[Kres1,Kres1,Kres1,Kres1],
  //   img:Kres1,
  //   size:null,
  //   subtitle:"Кресло премиум-класса",
  //   description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  // },
  // {
  //     id:2,
  //     title:"Офисное кресло",
  //     old:"350 000",
  //     new:"250 000",
  //     type:"МДФ",
  //     mebel:"Кресла",
  //     name:null,
  //     sale:null,
  //     img_list:[Kres2,Kres2,Kres2,Kres2],
  //     size:null,
  //     img:Kres2,
  //     subtitle:"Кресло премиум-класса",
  //     description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  //   },
  //   {
  //     id:3,
  //     title:"Офисное кресло",
  //     old:"350 000",
  //     new:"250 000",
  //     type:"МДФ",
  //     mebel:"Кресла",
  //     name:null,
  //     sale:null,
  //     img_list:[Kres3,Kres3,Kres3,Kres3],
  //     size:null,
  //     img:Kres3,
  //     subtitle:"Кресло премиум-класса",
  //     description:"Воплощение утонченности и комфорта. Оно объединяет высококачественные материалы, изысканный дизайн и передовые технологии для непревзойденного опыта отдыха"
  //   },
  {
    id: 79,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "14 700 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "EUN-01D20-1(L)left",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2000W*2080D*760H",
    img: img_79,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех. <br/>
Рабочая зона столешницы защищена бювером черного цвета. Встроенная тумба с левой стороны оснащена корпусом для проводки. <br/>
Отсеки и ящики в тумбе оснащены дверьми с мягкими закрытыми петлями. <br/>
Размер 2000W*2080D*760H <br/> 
Производство Китай`,
  },
  // {
  //   id: 80,
  //   title: "Столы для сотрудников | Open Varnish",
  //   old: null,
  //   new: "5 750 000",
  //   type: "МДФ",
  //   mebel: "Столы для сотрудников | Open Varnish",
  //   name: "EUN-01D20-1(L)left",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: "1400W*800D*760Hs",
  //   img: img_80,
  //   subtitle: "Столы для сотрудников | Open Varnish",
  //   description:
  //     "Стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех. Рабочая зона столешницы защищена бювером черного цвета. В комплекте стола Одна маленькая выдвижная тумбочка на роликах, одна тумба имеет выдвижные ящики оснащенные центральным замком. Производство Китай.",
  // },
  {
    id: 81,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "21 300 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "EUN-01D24-1(L)left",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*2080D*760H ",
    img: img_81,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака.<br/>
 Размер 2400W*2080D*760H. В комплектацию стола входит встроенная тумба а также встроенная длинная тумба под принтер. <br/>
Столешница оснащена бювером из черной эко-кожи для письма. Производство Китай.`,
  },
  {
    id: 82,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "11 250 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "ETH-01D24(R)",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*2000D*760H",
    img: img_82,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1 с 0,6 мм. натуральным шпоном ореха матовая поверхность, 8 слоев открытого лака Холм.<br/>
Цвет Орех+ Железно- серый. Столешница в боковой части имеет корпус для проводки.Тумба встроена с правой стороны и оснащена отсеками: дверью<br/>
шкафчиками и открытым отсеком. Боковая часть стола железныйкаркас с порошковым покрытие. <br/>
Размер 2400W*2000D*760H <br/>
Производство Китай.`,
  },
  {
    id: 83,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "25 900 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "EAF-01D22-1",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2200W*2060D*760H ",
    img: img_83,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех; <br/>
Изголовье с черным полиуретановым покрытием; Встроенные шкафчики с одной стороны ,с другой стороны тумба под телефон; <br/>
Выдвижные ящики оснащены замком. <br/>
Размер 2200W*2060D*760H <br/>
Производство Китай.`,
  },
  {
    id: 84,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "27 100 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "EAF-01D24-1",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*2000D*760H",
    img: img_84,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех; <br/>
Изголовье с черным полиуретановым покрытием; Встроенные шкафчики с одной стороны ,с другой стороны тумба под телефон; <br/>
Выдвижные ящики оснащены замком. <br/>
Размер 2200W*2060D*760H <br/>
Производство Китай.`,
  },
  {
    id: 85,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "27 150 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "EYS-01D24(R)",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*2100D*760H",
    img: img_85,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм матовая поверхность цвет Орех. <br/>
На тумбе расположен корпус для проводки, рабочая зона защищена бювером черного цвета. <br/>
Встроенная тумба справой стороны, двери с мягкими закрытыми петлями, ящики оснащены замком. (Имеет правую опцию тумбы) <br/>
Размер 2400W*2100D*760H <br/>
Производитель Китай.`,
  },
  {
    id: 86,
    title: "Столы для руководителей | Open Varnish",
    old: null,
    new: "21 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей | Open Varnish",
    name: "ELH-01D26(R)",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2650W*2000D*765H",
    img: img_86,
    subtitle: "Столы для руководителей | Open Varnish",
    description:
      `Стол из МДФ класса Е1, облицовка шпоном из натурального ореха 0,6мм, 8 слоев открытой лакокрасочной отделки.<br/>
Тумба встроена с правой стороны и имеет отсеки для файлов и оснащена выдвижными ящиками. <br/>
Размер 2650W*2000D*765H <br/>
Производство Китай.`,
  },
 
  {
    id: 4,
    title: "Столы для руководителей",
    old: null,
    new: "11 650 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    name: "B-2251",
    sale: null,
    img_list: [sdr_1, sdr_1, sdr_1, sdr_1],
    size: "2200W*1050D*760H",
    img: sdr_1,
    subtitle: "Столы для руководителей",
    description:
      "Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай",
  },
  {
    id: 5,
    title: "Столы для руководителей",
    old: null,
    new: "11 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_2, sdr_2, sdr_2, sdr_2],
    img: sdr_2,
    size: "2200W*1050D*760H",
    name: "B-2262",
    subtitle: "Столы для руководителей",
    description:
      "МДФ, облицовка шпон. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай",
  },
  {
    id: 6,
    title: "Столы для руководителей",
    old: null,
    new: "8 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_3, sdr_3, sdr_3, sdr_3],
    img: sdr_3,
    size: "1800W*900D*760H Байир Мастер",
    name: "B-1862",
    subtitle: "Столы для руководителей",
    description:
      "Стол из МДФ, облицовка шпон. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  },
  {
    id: 7,
    title: "Столы для руководителей",
    old: null,
    new: "9 900 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_4, sdr_4, sdr_4, sdr_4],
    img: sdr_4,
    size: "2000W*950D*760H",
    name: "B-2065",
    subtitle: "Столы для руководителей",
    description:
      "Стол из МДФ. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  },
  // {
  //   id: 8,
  //   title: "Столы для руководителей",
  //   old: null,
  //   new: "9 900 000",
  //   type: "МДФ",
  //   mebel: "Столы для руководителей",
  //   sale: null,
  //   img_list: [sdr_5, sdr_5, sdr_5, sdr_5],
  //   img: sdr_5,
  //   size: "2000W*950D*760H",
  //   name: "B-2065",
  //   subtitle: "Столы для руководителей",
  //   description:
  //     "Стол из МДФ. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  // },
  {
    id: 9,
    title: "Столы для руководителей",
    old: null,
    new: "9 900 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_6, sdr_6, sdr_6, sdr_6],
    img: sdr_6,
    size: "2000W*1000D*760H",
    name: "D804520Н",
    subtitle: "Столы для руководителей",
    description:
      "Материал: МДФ, облицовка шпон ценной породы дерева. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи). В комплектации стола две тумбы на колесиках.",
  },
  {
    id: 10,
    title: "Столы для сотрудников",
    old: null,
    new: "4 500 000",
    type: "МДФ",
    mebel: "Столы для сотрудников",
    sale: null,
    img_list: [sdr_7, sdr_7, sdr_7, sdr_7],
    img: sdr_7,
    size: "1600W*800D*760H.",
    name: "ED806016H",
    subtitle: "Столы для сотрудников",
    description:
      "Офисный стол из МДФ и шпона ореха, специальный лак. Встроенные тумбы.  На столешнице у каждого стола имеются специализированные проемы для проводки.",
  },
  {
    id: 11,
    title: "Столы для руководителей",
    old: null,
    new: "9 900 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdr_8, sdr_8, sdr_8, sdr_8],
    img: sdr_8,
    size: "2000W*1000D*760H.",
    name: "D803420H",
    subtitle: "Столы для руководителей",
    description:
      "Материал: МДФ, облицовка шпон ценной породы дерева. пеналом и надежно защищена встроенным бювером черного. В комплектации стола две тумбы на колесиках. Производитель Китай",
  },
  {
    id: 12,
    title: "Столы для руководителей",
    old: null,
    new: "6 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdm_1, sdm_1, sdm_1, sdm_1],
    img: sdm_1,
    size: "1600W*800D*760H",
    name: "B-1610",
    subtitle: "Столы для руководителей",
    description:
      "Стол из МДФ, рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  },
  {
    id: 13,
    title: "Столы для руководителей",
    old: null,
    new: "8 500 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdm_2, sdm_2, sdm_2, sdm_2],
    img: sdm_2,
    size: "1800W*900D*760H Байир Мастер",
    name: "B-1610",
    subtitle: "Столы для руководителей",
    description:
      "Стол из МДФ, облицовка шпон. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  В комплектацию стола входят две тумбы на колесиках. Производитель Китай.",
  },
  {
    id: 14,
    title: "Столы для руководителей",
    old: null,
    new: "8 850 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    sale: null,
    img_list: [sdm_3, sdm_3, sdm_3, sdm_3],
    img: sdm_3,
    size: "1800W*900D*760H",
    name: "HX-A1886",
    subtitle: "Столы для руководителей",
    description:
      "Стол материал: МДФ, облицовка шпон ценной породы дерева. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи). В комплектации стола две тумбы на колесиках: Производитель Китай.",
  },
  {
    id: 15,
    title: "Столы для сотрудников",
    old: null,
    new: "4 000 000",
    type: "МДФ",
    mebel: "Столы для сотрудников",
    sale: null,
    img_list: [sdm_4, sdm_4, sdm_4, sdm_4],
    img: sdm_4,
    size: "1400D *700W *760HКора Байир",
    name: "HX-1416-A",
    subtitle: "Столы для сотрудников",
    description:
      "Стол офисный с встроенными тумбами Материал: МДФ, облицовка шпон ценной породы дерева. Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета, две встроенные тумбы. Цвет темный орех Производитель Китай",
  },
  {
    id: 16,
    title: "Шкафы",
    old: null,
    new: "8 700 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_1, shkaf_1, shkaf_1, shkaf_1],
    img: shkaf_1,
    size: "1350W*420D* высота 2200H",
    name: "W-307B",
    subtitle: "Шкафы",
    description:
      "С ручками высокий Шкаф из МДФ, облицовка шпон. Отсеки для файлов. Двери стекло Производитель Китай.",
  },
  {
    id: 17,
    title: "Шкафы",
    old: null,
    new: "4 400 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_2, shkaf_2, shkaf_2, shkaf_2],
    img: shkaf_2,
    size: "900W*440D*2000H Байир Мастер",
    name: "W-208B",
    subtitle: "Шкафы",
    description:
      "Двух створчатый шкаф из МДФ, облицовка шпон. Отсеки для файлов. Двери стекло Производитель Китай.",
  },
  {
    id: 18,
    title: "Шкафы",
    old: null,
    new: "6 600 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_3, shkaf_3, shkaf_3, shkaf_3],
    img: shkaf_3,
    size: "1350W*420D*2000H Байир Мастер",
    name: "W-308B",
    subtitle: "Шкафы",
    description:
      "Шкаф трех створчатый из МДФ, облицовка шпон. Отсеки для файлов. Двери стекло. Производитель Китай",
  },
  {
    id: 19,
    title: "Шкафы",
    old: null,
    new: "4 200 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_4, shkaf_4, shkaf_4, shkaf_4],
    img: shkaf_4,
    size: "900W*440D*2000H",
    name: "W-201B",
    subtitle: "Шкафы",
    description:
      "Шкаф двух створчатый из МДФ, облицовка шпон. Отсеки для файлов. Двери стекло. Производитель Китай",
  },
  {
    id: 20,
    title: "Шкафы",
    old: null,
    new: "4 200 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_5, shkaf_5, shkaf_5, shkaf_5],
    img: shkaf_5,
    size: "900W*440D*2000H",
    name: "W-201 (L)",
    subtitle: "Шкафы",
    description:
      "Шкаф двух створчатый из МДФ, облицовка шпон. Отсеки для файлов, левый под гардероб. Производитель Китай.",
  },
  {
    id: 21,
    title: "Шкафы",
    old: null,
    new: "4 200 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_6, shkaf_6, shkaf_6, shkaf_6],
    img: shkaf_6,
    size: "900W*440D*2000H",
    name: "W-201(R)",
    subtitle: "Шкафы",
    description:
      " Шкаф двух створчатый из МДФ, облицовка шпон. Две распашные створки. Отсеки для файлов, правый отсек под гардероб. Производитель Китай.",
  },
  {
    id: 22,
    title: "Шкафы",
    old: null,
    new: "6 300 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_7, shkaf_7, shkaf_7, shkaf_7],
    img: shkaf_7,
    size: "1350W*440D*2000H",
    name: "W-301B",
    subtitle: "Шкафы",
    description:
      "Шкаф трех створчатый модель из МДФ, облицовка шпон. Три распашные створки. Отсеки для файлов, двери стекло. Производитель Китай",
  },
  {
    id: 23,
    title: "Шкафы",
    old: null,
    new: "6 300 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_8, shkaf_8, shkaf_8, shkaf_8],
    img: shkaf_8,
    size: "1350W*440D*2000H Байир Мастер",
    name: "W-301(L)",
    subtitle: "Шкафы",
    description:
      "Шкаф трех створчатый из МДФ, облицовка шпон. Три распашные створки, отсеки для файлов, левый отсек под гардероб. Производитель Китай.",
  },
  {
    id: 24,
    title: "Шкафы",
    old: null,
    new: "6 300 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_9, shkaf_9, shkaf_9, shkaf_9],
    img: shkaf_9,
    size: "1350W*440D*2000H",
    name: "W-301(R)",
    subtitle: "Шкафы",
    description:
      "Шкаф трех створчатый модель из МДФ, облицовка шпон. Три распашные створки. Отсеки для файлов, правый отсек под гардероб. Производитель Китай.",
  },
  {
    id: 25,
    title: "Шкафы",
    old: null,
    new: "8 500 000",
    type: "МДФ",
    mebel: "Шкафы",
    sale: null,
    img_list: [shkaf_10, shkaf_10, shkaf_10, shkaf_10],
    img: shkaf_10,
    size: "1800W*440D*2000H",
    name: "W-401",
    subtitle: "Шкафы",
    description:
      "Шкаф четырех створчатый из МДФ, облицовка шпон. Отсеки для файлов и гардероба. Производитель Китай.",
  },
  {
    id: 26,
    title: "Комоды",
    old: null,
    new: "4 200 000",
    type: "МДФ",
    mebel: "Комоды",
    sale: null,
    img_list: [komodi_1, komodi_1, komodi_1, komodi_1],
    img: komodi_1,
    size: "1200*400*850",
    name: "Комод H8106H",
    subtitle: "Комоды",
    description:
      "Комод из МДФ, облицовка шпон. Цвет темный орех. Распашные дверцы и два выдвижных шкафчика.  Производитель – Китай.",
  },
  {
    id: 27,
    title: "Комоды",
    old: null,
    new: "2 450 000",
    type: "МДФ",
    mebel: "Комоды",
    sale: null,
    img_list: [komodi_2, komodi_2, komodi_2, komodi_2],
    img: komodi_2,
    size: "800D*400W*850H",
    name: "EH8105H",
    subtitle: "Комоды",
    description:
      "Комод из МДФ, облицовка натуральный шпон. Цвет темный орех. Распашные дверцы и два выдвижных шкафчика.  Производитель – Китай.",
  },
  {
    id: 28,
    title: "Комоды",
    old: null,
    new: "2 650 000",
    type: "МДФ",
    mebel: "Комоды",
    sale: null,
    img_list: [komodi_3, komodi_3, komodi_3, komodi_3],
    img: komodi_3,
    size: "800W*420D*860H",
    name: "C-801",
    subtitle: "Комоды",
    description:
      "Комод из МДФ, облицовка шпон. Распашные створки, выдвижные ящики Цвет темный орех Производитель – Китай.",
  },
  {
    id: 29,
    title: "Комоды",
    old: null,
    new: "2 450 000",
    type: "МДФ",
    mebel: "Комоды",
    sale: null,
    img_list: [komodi_4, komodi_4, komodi_4, komodi_4],
    img: komodi_4,
    size: "800W*420D*860H",
    name: "C-802",
    subtitle: "Комоды",
    description:
      "Комод МДФ, облицовка шпон. Распашные створки, выдвижные ящики Цвет темный орех. Производитель – Китай.",
  },
  // {
  //   id: 30,
  //   title: "Журнальные столики",
  //   old: null,
  //   new: "3 450 000",
  //   type: "МДФ",
  //   mebel: "Журнальные столики",
  //   sale: null,
  //   img_list: [komodi_5, komodi_5, komodi_5, komodi_5],
  //   img: komodi_5,
  //   size: "600W*600D*650H",
  //   name: "J-850",
  //   subtitle: "Журнальные столики",
  //   description:
  //     "Журнальные столики из МДФ, облицовка шпон Цвет темный орех. Производитель – Китай.",
  // },
  {
    id: 31,
    title: "Журнальные столики",
    old: null,
    new: "2 800 000",
    type: "МДФ",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [komodi_6, komodi_6, komodi_6, komodi_6],
    img: komodi_6,
    size: "1200D*600W*420H",
    name: "EF 8104 H",
    subtitle: "Журнальные столики",
    description:
      "Журнальные столики из МДФ, облицовка шпон Цвет темный орех. Страна производитель – Китай. ",
  },
  {
    id: 32,
    title: "Журнальные столики",
    old: null,
    new: "2 800 000",
    type: "МДФ",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [komodi_7, komodi_7, komodi_7, komodi_7],
    img: komodi_7,
    size: "1200D*600W*420H",
    name: "EF 8102 H",
    subtitle: "Журнальные столики",
    description:
      "Журнальные столики из МДФ, облицовка шпон Цвет темный орех. Страна производитель – Китай. ",
  },
  {
    id: 33,
    title: "Журнальные столики",
    old: null,
    new: "1 800 000",
    type: "МДФ",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [komodi_8, komodi_8, komodi_8, komodi_8],
    img: komodi_8,
    size: "600D*600W*530H",
    name: "J-607",
    subtitle: "Журнальные столики",
    description:
      "Журнальные столики из МДФ, облицовка шпон Цвет темный орех. Страна производитель – Китай. ",
  },
  {
    id: 34,
    title: "Приставка",
    old: null,
    new: "3 950 000",
    type: "МДФ",
    mebel: "Приставка",
    sale: null,
    img_list: [komodi_9, komodi_9, komodi_9, komodi_9],
    img: komodi_9,
    size: "1300D*700W*730H (2-4 персоны)Байир Мастер 3  Кора Байир1 ",
    name: "H-1370",
    subtitle: "Приставка",
    description:
      "Приставной столик из МДФ, облицовка шпон. Цвет темный орех. Производитель Китай.",
  },
  {
    id: 35,
    title: "Конференц столы",
    old: null,
    new: "4 050 000",
    type: "МДФ",
    mebel: "Конференц столы",
    sale: null,
    img_list: [komodi_10, komodi_10, komodi_10, komodi_10],
    img: komodi_10,
    size: "1150W*1150D*760H (на 2-4 персоны) Кора Байир",
    name: "HX-02",
    subtitle: "Конференц столы",
    description: "Конференц стол Изготовлен из МДФ облицовка шпон. Китай.",
  },
  {
    id: 36,
    title: "Конференц столы",
    old: null,
    new: "7 200 000",
    type: "ЛДСП",
    mebel: "Конференц столы",
    sale: null,
    img_list: [konferens_1, konferens_1, konferens_1, konferens_1],
    img: konferens_1,
    size: "1150W*1150D*760H (на 2-4 персоны) Кора Байир",
    name: "2400×1100×750",
    subtitle: "Конференц столы",
    description:
      "Конференц стол для 8-10 персон из ПДСП. Меламина качества Е1. Стол оснащен тоннелем для проводки и встроенными полками для хранения документов. Производство Китай. Цена 7 200 000 сум..",
  },
  {
    id: 37,
    title: "Конференц столы",
    old: null,
    new: "3 480 000",
    type: "ЛДСП",
    mebel: "Конференц столы",
    sale: null,
    img_list: [konferens_2, konferens_2, konferens_2, konferens_2],
    img: konferens_2,
    size: "1000W*1000D*750H",
    name: "22EKD021",
    subtitle: "Конференц столы",
    description:
      "Конференц стол на 4 персоны из ЛДСП- Меламина качества Е1. Производство Китай. ",
  },
  {
    id: 38,
    title: "Конференц столы",
    old: null,
    new: "4 560 000",
    type: "ЛДСП",
    mebel: "Конференц столы",
    sale: null,
    img_list: [konferens_3, konferens_3, konferens_3, konferens_3],
    img: konferens_3,
    size: "1800х900×750",
    name: "26CKH001",
    subtitle: "Конференц столы",
    description:
      "Конференц стол на 6 персоны из ЛДСП- Меламина качества Е1. Производство Китай.",
  },
  {
    id: 39,
    title: "Конференц столы",
    old: null,
    new: "1 880 000",
    type: "ЛДСП",
    mebel: "Конференц столы",
    sale: null,
    img_list: [konferens_4, konferens_4, konferens_4, konferens_4],
    img: konferens_4,
    size: "Φ800*725H ",
    name: "27EKH004",
    subtitle: "Конференц столы",
    description:
      "Конференц стол круглый на 4 персоны цвет KALDI ЛДСП меламин Китай",
  },
  {
    id: 40,
    title: "Столы для переговоров ",
    old: null,
    new: "9 850 000",
    type: "ЛДСП",
    mebel: "Столы для переговоров ",
    sale: null,
    img_list: [konferens_5, konferens_5, konferens_5, konferens_5],
    img: konferens_5,
    size: "3600D*1240W*750H",
    name: "SO-C0136",
    subtitle: "Столы для переговоров ",
    description:
      "Конференц стол (на 10-12персон) цвет KALDI Кора Байир столешница оснащена  разьемом отверстием для кабель проводки ЛДСП меламин Китай ",
  },
  {
    id: 41,
    title: "Журнальные столики",
    old: null,
    new: "3 405 000",
    type: "ЛДСП",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [journal_1, journal_1, journal_1, journal_1],
    img: journal_1,
    size: "3600D*1240W*750H",
    name: "27TKH103",
    subtitle: "Журнальные столики",
    description:
      "Цвет KALDI Выдвижной ящик встроен в полку ,ЛДСП меламин Китай",
  },
  {
    id: 42,
    title: "Журнальные столики",
    old: null,
    new: "2 050 000",
    type: "ЛДСП",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [journal_2, journal_2, journal_2, journal_2],
    img: journal_2,
    size: "1200D*800W*450H",
    name: "GD-01F12",
    subtitle: "Журнальные столики",
    description: " цвет KALDI ЛДСП меламин Китай ",
  },
  {
    id: 43,
    title: "Журнальные столики",
    old: null,
    new: "1 650 000",
    type: "ЛДСП",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [journal_3, journal_3, journal_3, journal_3],
    img: journal_3,
    size: "1200W*600D*400H",
    name: "14TZJ005",
    subtitle: "Журнальные столики",
    description: "Выдвижной ящик встроен в полку ЛДСП меламин Китай",
  },
  {
    id: 44,
    title: "Журнальные столики",
    old: null,
    new: "1 025 000",
    type: "ЛДСП",
    mebel: "Журнальные столики",
    sale: null,
    img_list: [journal_4, journal_4, journal_4, journal_4],
    img: journal_4,
    size: "460W*360D*560H",
    name: "27TKH202",
    subtitle: "Журнальные столики",
    description:
      "Столешница из ЛДСП меламин Ножки серые-порошковое напыленае металлический каркас производство Китай",
  },
  {
    id: 45,
    title: "Ресепшн Стол",
    old: null,
    new: "10 157 000",
    type: "ЛДСП",
    mebel: "Ресепшн Стол",
    sale: null,
    img_list: [register_1_1, register_1_2, register_1_3],
    img: register_1_1,
    size: "2443W*670D*1050H",
    name: "27RZJ023",
    subtitle: "Ресепшн Стол",
    description:
      `Размер 2443W*670D*1050H <br/>
      Стойка Reception из ЛДСП класса Е1, Меламин. <br/>
      К стойке прилагаются встроенные шкафчики с полками изнутри, столешница оснащена тоннелем для проводки. С подсветкой. Производитель Китай.`,
  },
  {
    id: 46,
    title: "Ресепшн Стол",
    old: null,
    new: "8 280 000",
    type: "ЛДСП",
    mebel: "Ресепшн Стол",
    sale: null,
    img_list: [register_2_1, register_2_2],
    img: register_2_1,
    size: "2443W*670D*1050H",
    name: "27RZJ024",
    subtitle: "Ресепшн Стол",
    description:
      `Размер 1843W*670D*1050H  <br/>
      Стойка Reception из ЛДСП класса Е1, Меламин.  <br/>
      К стойке прилагаются встроенные шкафчики с полками изнутри, столешница оснащена тоннелем для проводки. С подсветкой. Производитель Китай.`,
  },
  {
    id: 47,
    title: "Ресепшн Стол",
    old: null,
    new: "10 157 000",
    type: "ЛДСП",
    mebel: "Ресепшн Стол",
    sale: null,
    img_list: [register_3_1, register_3_2],
    img: register_3_1,
    size: "2443W*670D*1050H",
    name: "27RKB023",
    subtitle: "Ресепшн Стол",
    description:
      `Размер 2443W*670D*1050H  <br/>
      Стойка Reception из ЛДСП класса Е1, Меламин. <br/>
      К стойке прилагаются встроенные шкафчики с полками изнутри, столешница оснащена тоннелем для проводки. С подсветкой. Производитель Китай.`,
  },
  {
    id: 48,
    title: "Столы для сотрудников",
    old: null,
    new: "1 600 000",
    type: "ЛДСП",
    mebel: "Столы для сотрудников",
    sale: null,
    img_list: [register_4_1],
    img: register_4_1,
    size: "2443W*670D*1050H",
    name: "Стол SL-12",
    subtitle: "Столы для сотрудников",
    description:
      "Фурнитура импортная, ЛДСП В комплекте Одна выдвижная тумба на роликах.  Цвет светлый орех. собственное производство Узбекистан",
  },
  {
    id: 49,
    title: "Кресла",
    old: null,
    new: "1 600 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX6119",
    sale: null,
    img_list: [Kres4, Kres4, Kres4, Kres4],
    size: "620W*595D*865H",
    img: Kres4,
    subtitle: "Кресла",
    description:
      "Кора Байир Стул с выдвижным пюпитором. Основание складной метало каркас,на 4х.ножках. Спинка обтянута прочной сеткой, сидушка матерчатая. Цвет черный Производство Китай.",
  },
  {
    id: 50,
    title: "Кресла",
    old: null,
    new: "1 600 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX6119(B)",
    sale: null,
    img_list: [Kres5, Kres5, Kres5, Kres5],
    size: " 620W*595D*865H",
    img: Kres5,
    subtitle: "Кресла",
    description:
      "Стул на роликах с выдвижным пюпитором. Основание складной метало каркас,на 4х.ножках - на роликах.  Спинка обтянута прочной сеткой, сидушка матерчатая. Цвет черный. Производство: Китай  ",
  },
  {
    id: 51,
    title: "Кресла",
    old: null,
    new: "3 175 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DL1707A (PU)",
    sale: null,
    img_list: [Kres6, Kres6, Kres6, Kres6],
    size: "680W*660D*1170H",
    img: Kres6,
    subtitle: "Кресла",
    description:
      " Офисное кресло с алюминиевой пятизвездочной базой на роликах. Обивка эко кожа коричневого цвета (высококачественный кож. заменитель.) Производство Китай ",
  },
  {
    id: 52,
    title: "Кресла",
    old: null,
    new: "2 400 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DL1706B (PU)",
    sale: null,
    img_list: [Kres7, Kres7, Kres7, Kres7],
    size: "630W*610D*960H",
    img: Kres7,
    subtitle: "Кресла",
    description:
      "Офисное кресло с прочной алюминиевой пятизвездочной базой на роликах. Спинка кресла имеет функцию наклона с фиксацией. Обивка эко кожа черного цвета (высококачественный коже заменитель.) Производство Китай.",
  },
  // {
  //   id: 53,
  //   title: "Кресла",
  //   old: null,
  //   new: "2 025 000",
  //   type: "ЛДСП",
  //   mebel: "Кресла",
  //   name: "76А039-5 PU",
  //   sale: null,
  //   img_list: [Kres8, Kres8, Kres8, Kres8],
  //   size: "570W*565D*1060H",
  //   img: Kres8,
  //   subtitle: "Кресла",
  //   description:
  //     "Кресло для персонала на колесиках, с механизмом регулирования высоты сиденья (газлифт) и наклона спинки, материал обивки – эко-кожа, подлокотники - металлические с накладками из эко-кожи, крестовина - металлическая, механизм качания, предельный вес нагрузки 120 кг. Цвет – черный.",
  // },
  {
    id: 54,
    title: "Кресла",
    old: null,
    new: "3 025 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DL1701A (1) (PU)",
    sale: null,
    img_list: [Kres8, Kres8, Kres8, Kres8],
    size: null,
    img: Kres8,
    subtitle: "Кресла",
    description:
      "Офисное кресло с прочной алюминиевой пятизвездочной базой на роликах. Спинка кресла имеет функцию наклона с фиксацией. Обивка эко кожа белого цвета (высококачественный кож. заменитель.) Производство Китай",
  },
  {
    id: 55,
    title: "Кресла",
    old: null,
    new: "1 610 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "76AHI064-3",
    sale: null,
    img_list: [Kres9, Kres9, Kres9, Kres9],
    size: null,
    img: Kres9,
    subtitle: "Кресла",
    description:
      "Кресло черного цвета с тканевой сидушкой спинка нейлоновая сетка. Производство Китай",
  },
  {
    id: 56,
    title: "Кресла",
    old: null,
    new: "1 500 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX6168B",
    sale: null,
    img_list: [Kres10, Kres10, Kres10, Kres10],
    size: "590W*660D*1075H",
    img: Kres10,
    subtitle: "Кресла",
    description:
      " Кресло на роликах. Спинка обтянута прочной сеткой, сидение матерчатое.  Цвет черный. Производство Китай",
  },
  {
    id: 57,
    title: "Кресла",
    old: null,
    new: "1 600 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "76DHI077-3",
    sale: null,
    img_list: [Kres11, Kres11, Kres11, Kres11],
    size: " 620W*595D*865H",
    img: Kres11,
    subtitle: "Кресла",
    description:
      "Стул с выдвижным пюпитором. Основание складной метало каркас,на 4х.ножках. Спинка обтянута прочной сеткой, сидушка матерчатая. Цвет черный Производство Китай.",
  },
  {
    id: 58,
    title: "Кресла",
    old: null,
    new: "1 450 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX6226C",
    sale: null,
    img_list: [Kres12, Kres12, Kres12, Kres12],
    size: " 590W*660D*980H ",
    img: Kres12,
    subtitle: "Кресла",
    description:
      "Кресло на металл. полозьях. Спинка обтянута прочной сеткой, сидушка матерчатая.  Цвет черный. Производство: Китай. ",
  },
  {
    id: 59,
    title: "Кресла",
    old: null,
    new: "1 300 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "76CHI077-3",
    sale: null,
    img_list: [Kres13, Kres13, Kres13, Kres13],
    size: " 590W*660D*980H ",
    img: Kres13,
    subtitle: "Кресла",
    description:
      "Кора Байир Кресло на колесах черного цвета с тканевой сидушкой спинка нейлоновая сетка. Производство Китай ",
  },
  {
    id: 60,
    title: "Кресла",
    old: null,
    new: "625 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX611",
    sale: null,
    img_list: [Kres14, Kres14, Kres14, Kres14],
    size: " 590W*660D*980H ",
    img: Kres14,
    subtitle: "Кресла",
    description:
      "Стул на 4ножках, на метало каркасе, Спинка и сидушка из полиуретана (прочны сплав пластмастика). Ножки и каркас алюминиевые с порошковым напылением серого цвета. Производство: Китай.",
  },
  {
    id: 61,
    title: "Кресла",
    old: null,
    new: "625 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "DX610",
    sale: null,
    img_list: [Kres15, Kres15, Kres15, Kres15],
    size: "550W*550D*800H",
    img: Kres15,
    subtitle: "Кресла",
    description:
      "Стул на 4ножках, на метало каркасе, Спинка и сидушка из полиуретана (прочны сплав пластмастика). Ножки и каркас алюминиевые с порошковым напылением серого цвета. Производство: Китай.",
  },
  {
    id: 62,
    title: "Кресла",
    old: null,
    new: "650 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "76C087",
    sale: null,
    img_list: [Kres16, Kres16, Kres16, Kres16],
    size: "770W*120D*600H",
    img: Kres16,
    subtitle: "Кресла",
    description:
      "Барный стул. Устойчивый барный стул на трех ножках. Кольцо и основание к ножкам железо, - ножки дерево. Сидушка полиуретан черного цвета. Производство Китай.",
  },
  {
    id: 62867,
    title: "Кресла",
    old: null,
    new: "1 350 000",
    type: "ЛДСП",
    mebel: "Кресла",
    name: "АР-12 Auditorium Chair",
    sale: null,
    // img_list: [Kres17, Kres17, Kres17, Kres17],
    size: "580*700*1030",
    img: Kres17,
    subtitle: "Кресла",
    description:
      `Театральное Кресло (для конференц залов/ кинозалов). <br/>
      Обивка сиденья и спинки матерчатая. В подлокотник встроен прочный выдвижной столик из дерева / подстаканник на железном каркасе. Цвет по вашему выбору. Из Китая под заказ. `,
  },
  {
    id: 63,
    title: "Шкафы",
    old: null,
    new: "1 600 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "26IKH616",
    sale: null,
    img_list: [shkaf_11, shkaf_11, shkaf_11, shkaf_11],
    size: " 2465W*410D*1700H",
    img: shkaf_11,
    subtitle: "Шкафы",
    description:
      "каркас никель-полки и ящики сьемные -на креплениях из ЛДСП меламин Китай",
  },
  {
    id: 64,
    title: "Шкафы",
    old: null,
    new: "2 935 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "28UKH401",
    sale: null,
    img_list: [shkaf_12, shkaf_12, shkaf_12, shkaf_12],
    size: "1650W*400D*850H",
    img: shkaf_12,
    subtitle: "Шкафы",
    description:
      "распашные двери 4створки, металлический каркас с черным порошковым покрытием ЛДСП меламин Китай",
  },
  {
    id: 65,
    title: "Шкафы",
    old: null,
    new: "11 500 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "14BZJ005",
    sale: null,
    img_list: [shkaf_13, shkaf_13, shkaf_13, shkaf_13],
    size: "1650W*400D*850H",
    img: shkaf_13,
    subtitle: "Шкафы",
    description:
      "цвет AKAZIE Распашные створки-–низ. Отсек для гардероба с права верхние полки для файлов из ЛДСП меламин Китай",
  },
  {
    id: 66,
    title: "Шкафы",
    old: null,
    new: "11 500 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "22BKD218",
    sale: null,
    img_list: [shkaf_14, shkaf_14, shkaf_14, shkaf_14],
    size: "1650W*400D*850H",
    img: shkaf_14,
    subtitle: "Шкафы",
    description:
      "цвет AKAZIE Распашные створки-–низ. Отсек для гардероба с права верхние полки для файлов из ЛДСП меламин Китай",
  },
  {
    id: 67,
    title: "Шкафы",
    old: null,
    new: "15 950 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "22BKD218",
    sale: null,
    img_list: [shkaf_15, shkaf_15, shkaf_15, shkaf_15],
    size: "800W*400D*1200H ",
    img: shkaf_15,
    subtitle: "Шкафы",
    description:
      "цвет KALDI Открытый верхний отсек, низ распашные двери ЛДСП меламин Китай",
  },
  {
    id: 68,
    title: "Шкафы",
    old: null,
    new: "5 700 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "30BZJ003",
    sale: null,
    img_list: [shkaf_16, shkaf_16, shkaf_16, shkaf_16],
    size: "1200W*400D*2010H",
    img: shkaf_16,
    subtitle: "Шкафы",
    description:
      "цвет AKAZIE  Распашные створки,, отсеки полки под файлы. Отсек под гардероб из ЛДСП меламин Китай",
  },
  {
    id: 69,
    title: "Шкафы",
    old: null,
    new: "3 405 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "26UKH358",
    sale: null,
    img_list: [shkaf_17, shkaf_17, shkaf_17, shkaf_17],
    size: "1200W*400D*1200H",
    img: shkaf_17,
    subtitle: "Шкафы",
    description:
      "цвет коди Открытый верхний отсек, низ распашные двери ЛДСП меламин Китай ",
  },
  {
    id: 70,
    title: "Столы Open Space",
    old: null,
    new: "7 050 000",
    type: "ЛДСП",
    mebel: "Столы Open Space",
    name: "33FZJ031",
    sale: null,
    img_list: [one_space_1, one_space_1, one_space_1, one_space_1],
    size: "1200W*2400D*750H",
    img: one_space_1,
    subtitle: "Столы Open Space",
    description:
      "тумбы встроены AKAZIE ЛДСП меламин КитайЭКРАННЫЕ ПЕРЕГОРОДКИ в комплекте ",
  },
  {
    id: 71,
    title: "Столы Open Space",
    old: null,
    new: "5 550 000",
    type: "ЛДСП",
    mebel: "Столы Open Space",
    name: "31FKH107",
    sale: null,
    img_list: [one_space_2, one_space_2, one_space_2, one_space_2],
    size: "1200W*2400D*750H",
    img: one_space_2,
    subtitle: "Столы Open Space",
    description:
      " Выкатные тумбы-2штуки ЛДСП меламин Китай, цвет KALDI ЭКРАН-Перегородка приобретается по желанию отдельно  экран: # 00KYZT001 . Размер: 800W*290D*9Н",
  },
  {
    id: 72,
    title: "Столы Open Space",
    old: null,
    new: "10 300 000",
    type: "ЛДСП",
    mebel: "Столы Open Space",
    name: "31FKH109",
    sale: null,
    img_list: [one_space_3, one_space_3, one_space_3, one_space_3],
    size: "2400W*1200D*750H",
    img: one_space_3,
    subtitle: "Столы Open Space",
    description:
      " Выкатные тумбы-4штуки выкатные тумбы на роликах  ЛДСП меламин Китай цвет KALDI  ЭКРАН-Перегородка приобретается по желанию отдельно экран:  00KYZT001 Размер: 800W*290D*9Н",
  },
  {
    id: 73,
    title: "Столы Open Space",
    old: null,
    new: "5 100 000",
    type: "ЛДСП",
    mebel: "Столы Open Space",
    name: "33FZJ025",
    sale: null,
    img_list: [one_space_4, one_space_4, one_space_4, one_space_4],
    size: "1200W*1200D*750H ",
    img: one_space_4,
    subtitle: "Столы Open Space",
    description:
      "тумбы встроены ящики на замке AKAZIE ЛДСП меламин Китай ЭКРАННЫЕ ПЕРЕГОРОДКИ в комплекте",
  },
  {
    id: 74,
    title: "Столы Open Space",
    old: null,
    new: "8 965 000",
    type: "ЛДСП",
    mebel: "Столы Open Space",
    name: "33FZJ027",
    sale: null,
    img_list: [one_space_5, one_space_5, one_space_5, one_space_5],
    size: "2400W*1200D*750H  ",
    img: one_space_5,
    subtitle: "Столы Open Space",
    description:
      "тумбы встроены ящики на замке  AKAZIE ЛДСП меламин Китай ЭКРАННЫЕ ПЕРЕГОРОДКИ в комплекте ",
  },
  {
    id: 75,
    title: "Столы для сотрудников",
    old: null,
    new: "8 500 000",
    type: "МДФ",
    mebel: "Столы для сотрудников",
    name: "B-1810",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1800W*900D*760H",
    img: img_75,
    subtitle: "Столы для сотрудников",
    description: `Стол из МДФ, облицовка шпон. <br/>
Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бювером черного цвета (имитация матовой кожи).  <br/>
В комплектацию стола входят две тумбы на колесиках. <br/>
Размер 1800W*900D*760H <br/>
Производитель Китай`,
  },
//   {
//     id: 76,
//     title: "Столы для руководителей",
//     old: null,
//     new: "9 900 000",
//     type: "МДФ",
//     mebel: "Столы для руководителей",
//     name: "ED804520H",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "2000W*1000D*760H",
//     img: img_76,
//     subtitle: "Столы для руководителей",
//     description:
//       `Стол из МДФ, облицовка шпон ценной породы дерева. <br/>
// Рабочая зона столешницы оснащена разъёмами для проводки, выдвижным пеналом и надежно защищена встроенным бюваром черного цвета (имитация матовой кожи). <br/>
// В комплектации стола две тумбы на колесиках. <br/>
// Размер 2000W*1000D*760H <br/>
// Производитель Китай.`,
//   },
  {
    id: 77,
    title: "Журнальные столики",
    old: null,
    new: "2 700 000",
    type: "МДФ",
    mebel: "Журнальные столики",
    name: "J-1207",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200D*600W*450H",
    img: img_77,
    subtitle: "Журнальные столики",
    description:
      `Журнальный стол из МДФ, облицовка шпон Цвет темный орех. <br/>
Размер 1200D*600W*450H<br/>
Производитель Китай.`,
  },
  {
    id: 78,
    title: "Журнальные столики",
    old: null,
    new: "1 800 000",
    type: "МДФ",
    mebel: "Журнальные столики",
    name: "J-602",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "600D*600W*510H",
    img: img_78,
    subtitle: "Журнальные столики",
    description:
      `Журнальный стол из МДФ, облицовка шпон Цвет темный орех. <br/>
Размер 600D*600W*510H <br/>
Производитель Китай.`,
  },
  
  {
    id: 87,
    title: "Шкафы | Open Varnish",
    old: null,
    new: "5 000 000",
    type: "МДФ",
    mebel: "Шкафы | Open Varnish",
    name: "ETY-02S08",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "800W*400D*2000H",
    img: img_87,
    subtitle: "Шкафы | Open Varnish",
    description:
      `Шкаф двухстворчатый из МДФ класса E1,с фанерой из натурального ореха ,6 мм, 8 слоев открытой лакокрасочной отделки. <br/>
Отсеки под фалы, двери распашные с мягкими закрытыми петлями. Закаленное стекло. <br/>
Размер 800W*400D*2000H <br/>
Производство Китай.`,
  },
  {
    id: 88,
    title: "Шкафы | Open Varnish",
    old: null,
    new: "5 000 000",
    type: "МДФ",
    mebel: "Шкафы | Open Varnish",
    name: "ETY-01S08",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "800W*400D*2000H",
    img: img_88,
    subtitle: "Шкафы | Open Varnish",
    description:
      `Шкаф двухстворчатый из МДФ класса E1, с фанерой из натурального ореха 0,6 мм, 8 слоев. <br/>
Отсеки под фалы с гардероб. Двери распашные с мягкими петлями. Закаленное стекло. <br/>
Размер 800W*400D*2000H <br/>
Производство Китай.`,
  },
  {
    id: 89,
    title: "Шкафы | Open Varnish",
    old: null,
    new: "5 240 000",
    type: "МДФ",
    mebel: "Шкафы | Open Varnish",
    name: "ETY-06S08",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "800W*400D*2000H",
    img: img_89,
    subtitle: "Шкафы | Open Varnish",
    description:
      `Шкаф из МДФ высшего сорта класса Е1, облицовка шпон с открытой лакокрасочной отделкой, аксессуары с высоким качеством. <br/>
Отсек под гардероб с правой стороны. <br/>
Размер 800W*400D*2000H <br/>
Производство Китай`,
  },
//   {
//     id: 90,
//     title: "Шкафы | Open Varnish",
//     old: null,
//     new: "5 240 000",
//     type: "МДФ",
//     mebel: "Шкафы | Open Varnish",
//     name: "ETY-04S08",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*2000H",
//     img: img_90,
//     subtitle: "Шкафы | Open Varnish",
//     description:
//       `Шкаф из МДФ высшего сорта класса Е1, облицовка шпон с открытой лакокрасочной отделкой, аксессуары с высоким качеством. <br/>
// Шкаф одностворчатый полки. Отсек под гардероб с левой стороны. <br/>
// Размер 800W*400D*2000H <br/>
// Производство Китай`,
//   },
//   {
//     id: 91,
//     title: "Шкафы | Open Varnish",
//     old: null,
//     new: "5 240 000",
//     type: "МДФ",
//     mebel: "Шкафы | Open Varnish",
//     name: "ETY-05S08",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*2000H",
//     img: img_91,
//     subtitle: "Шкафы | Open Varnish",
//     description:
//       `Шкаф из МДФ высшего сорта класса Е1, облицовка шпон с открытой лакокрасочной отделкой, аксессуары с высоким качеством, дверцы стекло. <br/>
// Размер 800W*400D*2000H <br/>
// Производство Китай.`,
//   },
  {
    id: 92,
    title: "Шкафы | Open Varnish",
    old: null,
    new: "7 840 000",
    type: "МДФ",
    mebel: "Шкафы | Open Varnish",
    name: "ETY-06S12",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200W*400D*2000H",
    img: img_92,
    subtitle: "Шкафы | Open Varnish",
    description:
      `Шкаф трехстворчатый из МДФ класса E1, с фанерой из натурального ореха 0,6 мм, 8 слоев. <br/>
Отсеки под фалы. с одной стороны гардероб. <br/>
Размер 1200W*400D*2000H <br/>
Двери распашные с мягкими петлями. Закаленное стекло.  <br/>
Производство Китай.`,
  },
//   {
//     id: 93,
//     title: "Комоды | Open Varnish",
//     old: null,
//     new: "5 500 000",
//     type: "МДФ",
//     mebel: "Комоды | Open Varnish",
//     name: "ESW-01H12",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "1200W*400D*850H",
//     img: img_93,
//     subtitle: "Комоды | Open Varnish",
//     description:
//       `Комод из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех. <br/>
// Размер 1200W*400D*850H <br/>
// Дверцы распашные с мягкими закрытыми петлями. <br/>
// Производство Китай.`,
//   },
  {
    id: 94,
    title: "Комоды | Open Varnish",
    old: null,
    new: "4 000 000",
    type: "МДФ",
    mebel: "Комоды | Open Varnish",
    name: "ESW-01H08",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "815W*400D*850H",
    img: img_94,
    subtitle: "Комоды | Open Varnish",
    description:
      `Комод из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех. <br/>
Размер 815W*400D*850H <br/>
Дверцы распашные с мягкими закрытыми петлями. <br/>
Производство Китай`,
  },
  {
    id: 95,
    title: "Журнальные столики | Open Varnish",
    old: null,
    new: "3 800 000",
    type: "МДФ",
    mebel: "Журнальные столики | Open Varnish",
    name: "EAF-01F07",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "700W*700D*420H",
    img: img_95,
    subtitle: "Журнальные столики | Open Varnish",
    description:
      `Журнальный стол из МДФ класса Е1 с натуральным шпоном ореха 0,6 мм, 8 слоев открытого лака холм цвет Орех. <br/>
Размер 700W*700D*420H <br/>
Производство Китай.`,
  },
  {
    id: 96,
    title: "Журнальные столики | Open Varnish",
    old: null,
    new: "6 200 000",
    type: "МДФ",
    mebel: "Журнальные столики | Open Varnish",
    name: "EAF-01F14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*420H",
    img: img_96,
    subtitle: "Журнальные столики | Open Varnish",
    description:
      `Журнальный стол из МДФ класса Е1 с натуральным шпоном.<br/>
Размер 1400W*700D*420H <br/>
Производство Китай`,
  },
  {
    id: 97,
    title: "Журнальные столики | Open Varnish",
    old: null,
    new: "3 700 000",
    type: "МДФ",
    mebel: "Журнальные столики | Open Varnish",
    name: "EJL-01F07",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "700W*700D*450H",
    img: img_97,
    subtitle: "Журнальные столики | Open Varnish",
    description:
      `Журнальный стол из МДФ класса Е1 с натуральным шпоном цвет Орех. <br/>
Размер 700W*700D*450H <br/>
Производство Китай.`,
  },
  {
    id: 98,
    title: "Журнальные столики | Open Varnish",
    old: null,
    new: "5 800 000",
    type: "МДФ",
    mebel: "Журнальные столики | Open Varnish",
    name: "EJL-01F14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*450H",
    img: img_98,
    subtitle: "Журнальные столики | Open Varnish",
    description:
      `Журнальный стол из МДФ класса Е1 с натуральным шпоном цвет Орех.<br/>
Размер 1400W*700D*450H <br/>
Производство Китай.`,
  },
//   {
//     id: 99,
//     title: "Комоды | Open Varnish",
//     old: null,
//     new: "1 500 000",
//     type: "МДФ",
//     mebel: "Комоды | Open Varnish",
//     name: "EUN81",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "380W*480D*535H",
//     img: img_99,
//     subtitle: "Комоды | Open Varnish",
//     description:
//       `Тумба на роликах из МДФ класса Е1 с натуральным шпоном ореха. <br/>
// Размер 380W*480D*535H <br/>
// Тумба с тремя выдвижными ящиками, верхний ящик  оснащена центральным замком. <br/>
// Производство Китай.`,
//   },
  {
    id: 100,
    title: "Модульная Мебель | Open Varnish",
    old: null,
    new: "5 700 000",
    type: "МДФ",
    mebel: "Модульная Мебель | Open Varnish",
    name: "DS-147001",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400*700*760",
    img: img_100,
    subtitle: "Модульная Мебель | Open Varnish",
    description:
      `Приставной стол из МДФ с 0,6 мм натуральным шпоном ореха, 8 слоев открытого лака. <br/>
Размер 1400*700*760 <br/>
Порошковое покрытие стальной ножки. <br/>
(Подходит для моделей ETH- стол с железными ножками) Производство Китай.`,
  },
  {
    id: 101,
    title: "Модульная Мебель | Open Varnish",
    old: null,
    new: "5 805 000",
    type: "МДФ",
    mebel: "Модульная Мебель | Open Varnish",
    name: "DS-147002",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400*700*730",
    img: img_101,
    subtitle: "Модульная Мебель | Open Varnish",
    description:
      `Приставной стол из МДФ с 0,6 мм натуральным шпоном ореха, 8 слоев открытого лака. <br/>
Размер 1400*700*730 <br/>
Подходит для моделей из серии матовый шпон к столам размера от 1,80см до 3,20см.  <br/>
Производство Китай.`,
  },
  {
    id: 102,
    title: "Модульная Мебель | Столы Open Space",
    old: null,
    new: "4 560 000",
    type: "ЛДСП",
    mebel: "Модульная Мебель | Столы Open Space",
    name: "LB-L1B12GL",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1430W*1230D*1100H",
    img: img_102,
    subtitle: "Модульная Мебель | Столы Open Space",
    description:
      `Модульный стол для сотрудников из крашенного ЛДСП. <br/>
Под заказ. <br/>
Производство Китай.`,
  },
//   {
//     id: 103,
//     title: "Столы для переговоров",
//     old: null,
//     new: "5 000 000",
//     type: "ЛДСП",
//     mebel: "Столы для переговоров",
//     name: "ME-C0224",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "2400D*100W*760H",
//     img: img_103,
//     subtitle: "Столы для переговоров",
//     description:
//       `Конференц стол (на 8-10 персон) <br/>
// Размер 2400D*100W*760H. <br/>
// Столешница оснащена встроеной коробкой с разьемом для кабель проводки. Цвет темный кофе. <br/>
// ЛДСП- Меламин. Производитель Китай.`,
//   },
//   {
//     id: 104,
//     title: "Комоды | Шкафы",
//     old: null,
//     new: "1 595 000",
//     type: "ЛДСП",
//     mebel: "Комоды | Шкафы",
//     name: "26UKH258",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*1200H",
//     img: img_104,
//     subtitle: "Комоды | Шкафы",
//     description:
//       `Шкаф- тумба <br/>
// Размер 800W*400D*1200H  <br/>
// Открытый верхний отсек, низ распашные двери. Цвет KALDI. <br/>
// ЛДСП-Меламин. Производитель Китай.`,
//   },
//   {
//     id: 105,
//     title: "Шкафы",
//     old: null,
//     new: "3 700 000",
//     type: "ЛДСП",
//     mebel: "Шкафы",
//     name: "KB-S08040-HT",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*2000H",
//     img: img_105,
//     subtitle: "Шкафы",
//     description:
//       `Шкаф двухстворчатый <br/>
// Размер 800W*400D*2000H <br/>
// Одна стеклянная створка и гардеробный отсек, полки под файлы. <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
//   {
//     id: 106,
//     title: "Шкафы",
//     old: null,
//     new: "3 700 000",
//     type: "ЛДСП",
//     mebel: "Шкафы",
//     name: "KB-S18040-HT",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*2000H",
//     img: img_106,
//     subtitle: "Шкафы",
//     description:
//       `Шкаф двухстворчатый <br/>
// Размер 800W*400D*2000H <br/>
// Распашные стеклянные створки,отсеки полки под файлы. <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
//   {
//     id: 107,
//     title: "Шкафы",
//     old: null,
//     new: "3 700 000",
//     type: "ЛДСП",
//     mebel: "Шкафы",
//     name: "KB-S28040-HT",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800W*400D*2000H",
//     img: img_107,
//     subtitle: "Шкафы",
//     description:
//       `Шкаф двухстворчатый <br/>
// Размер 800W*400D*2000H <br/>
// Распашные стеклянные створки,отсеки полки под файлы. <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
//   {
//     id: 108,
//     title: "Шкафы | Комоды",
//     old: null,
//     new: "1 400 000",
//     type: "ЛДСП",
//     mebel: "Шкафы | Комоды",
//     name: "SO-S1608",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800D*400W*1100H",
//     img: img_108,
//     subtitle: "Шкафы | Комоды",
//     description:
//       `Шкаф- тумба  <br/>                   
// Размер 800D*400W*110H <br/>
// Открытый верхний отсек, низ распашные двери. Цвет "Coffee". <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
//   {
//     id: 109,
//     title: "Шкафы | Комоды",
//     old: null,
//     new: "1 800 000",
//     type: "ЛДСП",
//     mebel: "Шкафы | Комоды",
//     name: "SO-S1708",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800D*400W*1140H",
//     img: img_109,
//     subtitle: "Шкафы | Комоды",
//     description:
//       `Шкаф- тумба      <br/>                   
// Размер 800D*400W*110H <br/>
// Открытый верхний отсек, низ распашные двери. Цвет "Светлое дерево". <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
//   {
//     id: 110,
//     title: "Шкафы",
//     old: null,
//     new: "2 550 000",
//     type: "ЛДСП",
//     mebel: "Шкафы",
//     name: "SO-S0508",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "800D*400W*2000H",
//     img: img_110,
//     subtitle: "Шкафы",
//     description:
//       `Шкаф двухстворчатый<br/>
// Размер 800W*400D*2000H <br/>
// Открытый верхний отсек, низ распашные двери и полки для файлов. <br/>
// ЛДСП-Меламин. <br/>
// Производитель Китай.`,
//   },
  {
    id: 111,
    title: "мягкая мебель",
    old: null,
    new: "10 500 000",
    type: "",
    mebel: "мягкая мебель",
    name: "Честер2022",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2300D*950W*7500H",
    img: img_111,
    subtitle: "мягкая мебель",
    description:
      `Диван «Честер2022»  <br/>
Фурнитура импортная, Обивка импортная эко кожа, Утяжки обивки делаются вручную.  <br/>
Собственное Производство BAYIR  <br/>
Размеры: 2300D*950W*7500H <br/>
ЦВЕТА:Коричневый, Черный, Зеленый , Красный `,
  },
  {
    id: 112,
    title: "мягкая мебель",
    old: null,
    new: "6 800 000",
    type: "",
    mebel: "мягкая мебель",
    name: "мини Честер2022",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1800D*800W*7500H",
    img: img_114,
    subtitle: "мягкая мебель",
    description:
      `Диван «мини Честер2022»  <br/>
Фурнитура импортная, Обивка импортная эко кожа, Утяжки обивки делаются вручную.  <br/>
Собственное Производство BAYIR<br/> 
Размеры:1800D*800W*7500H    <br/>
ЦВЕТА:Коричневый, Черный, Зеленый , Красный `,
  },
  {
    id: 113,
    title: "мягкая мебель",
    old: null,
    new: "4 200 000",
    type: "",
    mebel: "мягкая мебель",
    name: "Кресло «Честер2022»   ",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1100D*950W*7500H",
    img: img_112,
    subtitle: "мягкая мебель",
    description:
      `Кресло «Честер2022» <br/>
Фурнитура импортная, Обивка импортная эко кожа, Утяжки обивки делаются вручную.  <br/>
Собственное Производство BAYIR <br/>
Размеры:-1100D*950W*7500H  <br/>
ЦВЕТА: Коричневый, Черный, Зеленый , Красный `,
  },
  {
    id: 114,
    title: "мягкая мебель",
    old: null,
    new: "16 500 000",
    type: "",
    mebel: "мягкая мебель",
    name: "Комплект «Честер2022»",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "Диван- 2300D*950W*7500H | Кресло-1100D*950W*7500H",
    img: img_113,
    subtitle: "мягкая мебель",
    description: 
    `Комплект «Честер2022» - Диван трехместный и два кресла. <br/>
    Фурнитура импортная, Обивка импортная эко кожа, Утяжки обивки делаются вручную. <br/>
    Собственное Производство BAYIR <br/>
    ЦВЕТА: Коричневый, Черный, Зеленый , Красный,`
  },
  {
    id: 115,
    title: "мягкая мебель",
    old: null,
    new: "8 600 000",
    type: "",
    mebel: "мягкая мебель",
    name: "ДИВАН «YM862-2022»",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2000D*900W*750H",
    img: img_115,
    subtitle: "мягкая мебель",
    description:
      `Фурнитура импортная, Обивка эко кожа, имитация матовой классической кожи.Утяжки обивки делаются вручную. Каркас наножках, хромированный железный . Цвет черный. <br/>
Собственное Производство BAYIR `,
  },
  {
    id: 116,
    title: "мягкая мебель",
    old: null,
    new: "3 500 000",
    type: "",
    mebel: "мягкая мебель",
    name: "Кресло YM862-2022",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "900D*900W*7500H",
    img: img_116,
    subtitle: "мягкая мебель",
    description:
      `Фурнитура импортная, Обивка эко кожа, имитация матовой классической кожи. Утяжки обивки делаются вручную. Каркас на ножках, хромированный железный.  <br/>
Цвет черный. <br/>
Собственное Производство BAYIR`,
  },
  // {
  //   id: 117,
  //   title: "мягкая мебель",
  //   old: null,
  //   new: "14 400 000",
  //   type: "",
  //   mebel: "мягкая мебель",
  //   name: "YM862-2022",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: "900D*900W*7500H",
  //   img: img_117,
  //   subtitle: "мягкая мебель",
  //   description:
  //     "Фурнитура импортная, Обивка эко кожа, имитация матовой классической кожи. Утяжки обивки делаются вручную. Каркас на ножках, хромированный железный. Цвет черный. Собственное Производство BAYIR",
  // },
  // {
  //   id: 118,
  //   title: "мягкая мебель",
  //   old: null,
  //   new: "5850 000",
  //   type: "",
  //   mebel: "мягкая мебель",
  //   name: "YM-118",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: " 3х: 1800D*770W*800H",
  //   img: img_118,
  //   subtitle: "мягкая мебель",
  //   description:
  //     "Диван трех местный модель «YM-118» Фурнитура импортная, Обивка эко кожа, имитация матовой классической кожи. Утяжки обивки делаются вручную. Каркас на изогнутых ножках, хромированный. Цвет черный. Собственное Производство BAYIR ",
  // },
  // {
  //   id: 119,
  //   title: "мягкая мебель",
  //   old: null,
  //   new: "13000 000",
  //   type: "",
  //   mebel: "мягкая мебель",
  //   name: "JF 008",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: " 2400D*1000W*950H",
  //   img: img_119,
  //   subtitle: "мягкая мебель",
  //   description:
  //     "комплект Комплект-диван и 2 кресла. Фурнитура импортная.. Собственное Производство BAYIR",
  // },
  {
    id: 120,
    title: "Кресла",
    old: null,
    new: "17 950 000",
    type: "МДФ",
    mebel: "Кресла",
    name: "RM-6087A",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "780*730*1240",
    img: img_120,
    subtitle: "Кресла",
    description:
      `Руководительское кресло изготовленное из натуральной кожи с фигурной набивкой из губки. <br/>
Высота регулируется, с функцией наклона. Алюминиевая пятизвездочная ножка с роликами из полиамида. <br/>
Размер:7780*730*1240 <br/>
Цвет: Кожа серого цвета, спинка из темно серого дерева (МДФ). `,
  },
  {
    id: 121,
    title: "Журнальные столики",
    old: null,
    new: "8 950 000",
    type: "",
    mebel: "Журнальные столики",
    name: "EMOG-01F12",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "780*730*1240",
    img: img_121,
    subtitle: "Журнальные столики",
    description:
      `Журнальный столик прямоугольной формы. <br/>
Каркас стальной, столешница из мрамора. <br/>
Размер: 1200W*600D*400H.  <br/>
Цвет: Белый мрамор+ железно серый. `,
  },
  {
    id: 122,
    title: "Журнальные столики",
    old: null,
    new: "5 000 000",
    type: "",
    mebel: "Журнальные столики",
    name: "EMOG-01F05",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "Dia 500*495H",
    img: img_122,
    subtitle: "Журнальные столики",
    description:
      `Журнальный столик круглой формы. <br/>
Каркас стальной, столешница из мрамора.<br/>
 Размер: Dia 500* 495H. <br/>
Цвет: Белый мрамор+ железно серый. `
  },
  {
    id: 123,
    title: "Кресла ",
    old: null,
    new: "11 950 000",
    type: "",
    mebel: "Кресла ",
    name: "YZ001A",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "700W*730D*1270~1320H",
    img: img_123,
    subtitle: "Кресла ",
    description:
      `Руководительское кресло изготовленное из натуральной кожи с фигурной набивкой из губки. <br/>
 Высота регулируется, с функцией наклона. Алюминиевая пятизвездочная ножка с роликами из полиамида. <br/>
Размер:700W*730D*1270 <br/>
Цвет: Серый`,
  },
  {
    id: 124,
    title: "Журнальные столики ",
    old: null,
    new: "3 800 000",
    type: "",
    mebel: "Журнальные столики ",
    name: "GXK-06F07",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "700*700*320",
    img: img_124,
    subtitle: "Журнальные столики ",
    description:
      `Журнальный стол из МДФ класса E1. <br/>
Размер:700*700*320.  <br/>
Цвет: Каменно-лунный белый + нефритовое серебро.`,
  },
  {
    id: 125,
    title: "Шкафы",
    old: null,
    new: "40 300 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "EMF-02S32",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3200*400*2000",
    img: img_125,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологического шпона из МДФ класса E1. <br/>
К шкафу прилагаются открытые полки для хранения файлов и закрытие полки для хранения документов. <br/>
Размер: 3200*400*2000.  <br/>
Цвет: Лазурно-синий +бежево- серый. <br/>
С подсветкой. `,
  },
  {
    id: 126,
    title: "Комоды",
    old: null,
    new: "10 100 000",
    type: "МДФ",
    mebel: "Комоды",
    name: "ELRS-01H16",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1600*400*900",
    img: img_126,
    subtitle: "Комоды",
    description:
      `Комод из экологически чистого  МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8-слойная окраска на водной основе. <br/>
К комоду приложенны две распашные двери с плавно закрывающимися петлями и скрытой ручкой. <br/>
Имеются также  открытые полки по бокам. <br/>
Размер:1600*400*900. <br/>
Цвет: Лазурно-синий + голубино-серый.`,
  },
  {
    id: 127,
    title: "Столы для руководителей",
    old: null,
    new: "35 300 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    name: "EMF-02D26",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2560*2000*760",
    img: img_127,
    subtitle: "Столы для руководителей",
    description:
      "Руководительский стол с вытянутой ножкой из экологического шпона из МДФ класса E1. Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. (Перед сборкой тумбу можно установить как справа так и слева). Размер: 2560*2000*760. Цвет: Лазурно-синий + голубино-серый.",
  },
  {
    id: 128,
    title: "Кресла",
    old: null,
    new: "13 850 000",
    type: "МДФ",
    mebel: "Кресла",
    name: "JY-008-A Genuine Leather",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "800*810（1260-1325）",
    img: img_128,
    subtitle: "Кресла",
    description:
      `Руководительское кресло изготовленное из натуральной кожи с фигурной набивкой из губки. <br/>
Высота регулируется, с функцией наклона. Алюминиевая пятизвездочная ножка с роликами из полиамида. <br/>
Цвет: Бежевая кожа с голубой спинкой. <br/>
Размер: 800*810*1260.`,
  },
  {
    id: 129,
    title: "журнальные столики",
    old: null,
    new: "4 400 000",
    type: "МДФ",
    mebel: "журнальные столики",
    name: "SLO-01F06",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "600*600*400",
    img: img_129,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из МДФ класса E1. <br/>
Размер: 600*600*400.  <br/>
Цвет: Зеленый грейпфрут + светло-серый.`,
  },
  {
    id: 130,
    title: "журнальные столики",
    old: null,
    new: "10 750 000",
    type: "МДФ",
    mebel: "журнальные столики",
    name: "SLO-01F14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*400H",
    img: img_130,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из МДФ класса E1. <br/>
Размер: 1400W*700D*400H. <br/>
Цвет: Зеленый грейпфрут + светло-серый.`,
  },
  {
    id: 131,
    title: "Комоды",
    old: null,
    new: "10 800 000",
    type: "МДФ",
    mebel: "Комоды",
    name: "SLO-01H16",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1600W*400D*1000H",
    img: img_131,
    subtitle: "Комоды",
    description:
      `Комод из экологического шпона из МДФ класса E1.<br/>
Размер: 1600W*400D*1000H.  <br/>
Цвет: Зеленый грейпфрут + светло-серый.`,
  },
  {
    id: 132,
    title: "Шкафы",
    old: null,
    new: "44 100 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "SLO-01S30",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3040W*400D*2000H",
    img: img_132,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологического шпона из МДФ класса E1.<br/>
Размер: 3040W*400D*2000H. <br/>
Цвет: Зеленый грейпфрут + светло-серый. <br/>
С подсветкой.`,
  },
  {
    id: 133,
    title: "Столы для руководителей",
    old: null,
    new: "36 550 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    name: "SLO-01D24",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*1825D*760H",
    img: img_133,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из экологического шпона из МДФ класса E1. <br/>
Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. <br/>
(Перед сборкой тумбу можно установить как справа так и слева). <br/>
Размер: 2400W*1825D*760H. <br/>
Цвет: Зеленый грейпфрут + светло-серый. <br/>
Стол с подсветкой.`,
  },
  {
    id: 134,
    title: "журнальные столики",
    old: null,
    new: "2 450 000",
    type: "ЛДСП",
    mebel: "журнальные столики",
    name: "BBP-F0112",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200W*600D*450H",
    img: img_134,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor". <br/>
Размер:1200W*600D*450H. <br/>
Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 135,
    title: "Столы для переговоров",
    old: null,
    new: "24 650 000",
    type: "ЛДСП",
    mebel: "Столы для переговоров",
    name: "BBP-C0136",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1400D*760H",
    img: img_135,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor". <br/>
Размер: 3600W*1400D*760H. <br/>
Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 136,
    title: "Комоды",
    old: null,
    new: "4 250 000",
    type: "ЛДСП",
    mebel: "Комоды",
    name: "BBP-H0112",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200W*900D*400H",
    img: img_136,
    subtitle: "Комоды",
    description:
      `Комод из экологически ДСП класса E1 с импортной меламиновой отделкой Schattdecor. <br/>
Размер:1200W*900D*400H. <br/>
Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 137,
    title: "Шкафы",
    old: null,
    new: "24 350 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "BBP-S0130",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3000*400*2000",
    img: img_137,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологически чистой ДСП класса Е1 с меламиновой отделкой.<br/>
 Размер: 3000*400*2000.<br/>
 Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 138,
    title: "Столы для руководителей",
    old: null,
    new: "18 250 000",
    type: "ЛДСП",
    mebel: "Столы для руководителей",
    name: "BBP-D0128",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2800*2100*760",
    img: img_138,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из экологически чистой ДСП класса Е1 с меламиновой отделкой. <br/>
Перед сборкой длинную тумбу можно поменять местами слева или справа.<br/>
Размер: 2800*2100*760. <br/>
Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 139,
    title: "Столы для руководителей",
    old: null,
    new: "17 250 000",
    type: "ЛДСП",
    mebel: "Столы для руководителей",
    name: "BBP-D0124",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400*1820*760",
    img: img_139,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из экологически чистой ДСП класса Е1 с меламиновой отделкой. <br/>
Перед сборкой длинную тумбу можно поменять местами слева или справа.<br/>
Размер: 2400*1820*760. <br/>
Цвет: Поп-Дуб + Небесный Мокко.`,
  },
  {
    id: 140,
    title: "журнальные столики",
    old: null,
    new: "5 900 000",
    type: "ЛДСП",
    mebel: "журнальные столики",
    name: "BLL-F0114",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*450H",
    img: img_140,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor". <br/>
Размер: 1400W*700D*450H. <br/>
Цвет: Серый дуб + железно-серый.`,
  },
  {
    id: 141,
    title: "Столы для переговоров",
    old: null,
    new: "15 400 000",
    type: "ЛДСП",
    mebel: "Столы для переговоров",
    name: "BLL-C0124",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*1200D*760H",
    img: img_141,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor".<br/>
Размер: 2400W*1200D*760H. <br/>
Цвет: Серый дуб + железно-серый.`,
  },
  {
    id: 142,
    title: "Комоды",
    old: null,
    new: "6 400 000",
    type: "ЛДСП",
    mebel: "Комоды",
    name: "BLL-H0113",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1350W*400D*850H",
    img: img_142,
    subtitle: "Комоды",
    description:
      `Комод из экологически чистой ДСП класса E1 с импортной меламиновой отделкой "Schattdecor". <br/>
Размер:1350W*400D*850H.<br/>
 Цвет: Серый дуб + железно-серый.`,
  },
  {
    id: 143,
    title: "Шкафы",
    old: null,
    new: "14 450 000",
    type: "ЛДСП",
    mebel: "Шкафы",
    name: "BLL-S0124",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400*400*2100",
    img: img_143,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологически чистой ДСП класса Е1 с меламиновой отделкой. <br/>
Размер: 2400*400*2100. <br/>
Цвет: Серый дуб + железно-серый.`,
  },
  {
    id: 144,
    title: "Столы для руководителей",
    old: null,
    new: "17 750 000",
    type: "ЛДСП",
    mebel: "Столы для руководителей",
    name: "BLL-D0124",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400*1900*760",
    img: img_144,
    subtitle: "Столы для руководителей",
    description:
      `Стол из экологически чистой ДСП класса Е1 с меламиновой отделкой. <br/>
Фиксированная длинная тумба под принтер с правой стороны. <br/>
Размер: 2400*1900*760. <br/>
Цвет: Серый дуб + железно-серый.`,
  },
  {
    id: 145,
    title: "журнальные столики",
    old: null,
    new: "5 050 000",
    type: "МДФ",
    mebel: "журнальные столики",
    name: "BBT-01F07",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "700W*700D*450H",
    img: img_145,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из МДФ класса E1. <br/>
Размер 700W*700D*450H <br/>
Цвет: Розовое золото + песочно-коричневый`,
  },
  {
    id: 146,
    title: "журнальные столики",
    old: null,
    new: "7 050 000",
    type: "МДФ",
    mebel: "журнальные столики",
    name: "BBT-01F14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*450H",
    img: img_146,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из МДФ класса E1.<br/>
Размер 1400W*700D*450H. <br/>
Цвет: Розовое золото + песочно-коричневый.`,
  },
  {
    id: 147,
    title: "Шкафы",
    old: null,
    new: "38 250 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "BBT-01S30",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3000W*600D*2100H",
    img: img_147,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологического шпона из МДФ класса E1. <br/>
Размер 3000W*600D*2100H. <br/>
Цвет: Розовое золото + песочно-коричневый. <br/>
С подсветкой.`,
  },
  {
    id: 148,
    title: "Столы для переговоров",
    old: null,
    new: "31 550 000",
    type: "МДФ",
    mebel: "Столы для переговоров",
    name: "BBT-01C36",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1350D*760H",
    img: img_148,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из экологического шпона из МДФ класса E1. <br/>
Размер: 3600W*1350D*760H. <br/>
Цвет: Розовое золото + песочно-коричневый.`,
  },
  {
    id: 149,
    title: "Столы для переговоров",
    old: null,
    new: "47 600 000",
    type: "МДФ",
    mebel: "Столы для переговоров",
    name: "BBT-01C48",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "4800W*1500D*760H",
    img: img_149,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из экологического шпона из МДФ класса E1. <br/>
Размер: 4800W*1500D*760H. <br/>
Цвет: Розовое золото + песочно-коричневый.`,
  },
  {
    id: 150,
    title: "Столы для руководителей",
    old: null,
    new: "29 400 000",
    type: "МДФ",
    mebel: "Столы для руководителей",
    name: "BBT-01D24",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*1900D*760H",
    img: img_150,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из экологического шпона из МДФ класса E1. <br/>
Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов. <br/>
(Перед сборкой тумбу можно установить как справа так и слева). <br/>
Размер: 2400W*1900D*760H. <br/>
Цвет: Розовое золото + песочно-коричневый. <br/>
Стол с подсветкой.`,
  },
  {
    id: 151,
    title: "Кресла ",
    old: null,
    new: "7 550 000",
    type: "",
    mebel: "Кресла ",
    name: "RM-2002A",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "660*690*1320",
    img: img_151,
    subtitle: "Кресла ",
    description:
      `Руководительское кресло изготовленное из высококачественной кожи из микрофибры.<br/>
 Имеет многофункциональное шасси, регулируемая высота, со всеми 4-мя углами блокировки функции наклона. <br/>
Алюминиевые пятизвездочные ножки с роликами из полиуретана. Г<br/>
убка высокой плотности.<br/>
 Размер: 660*690*1320.`,
  },
  {
    id: 152,
    title: "журнальные столики ",
    old: null,
    new: "10 050 000",
    type: "МДФ",
    mebel: "журнальные столики ",
    name: "BDL-01F14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*450H",
    img: img_152,
    subtitle: "журнальные столики ",
    description:
      `Журнальный столик из экологического шпона из МДФ класса E1. <br/>
Размер: 1400W*700D*450H. <br/>
Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый.`,
  },
  {
    id: 153,
    title: "Комоды ",
    old: null,
    new: "8 200 000",
    type: "МДФ",
    mebel: "Комоды ",
    name: "BDL-01H12",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200W*400D*900H",
    img: img_153,
    subtitle: "Комоды ",
    description:
      `Комод из экологического шпона из МДФ класса E1. <br/>
Размер: 1200W*400D*900H. <br/>
Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый.`,
  },
  {
    id: 154,
    title: "Столы для переговоров ",
    old: null,
    new: "46 100 000",
    type: "МДФ",
    mebel: "Столы для переговоров ",
    name: "GXK-02C36",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1350D*760H",
    img: img_154,
    subtitle: "Столы для переговоров ",
    description:
      `Конференц стол из экологического шпона из МДФ класса E1. <br/>
Размер: 3600W*1350D*760H. <br/>
Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый.`,
  },
  {
    id: 155,
    title: "Шкафы",
    old: null,
    new: "41 600 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "BDL-01S30",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3000W*400D*2100H",
    img: img_155,
    subtitle: "Шкафы",
    description:
      `Шкаф из экологического шпона из МДФ класса E1.<br/>
 Размер: 3000W*400D*2100H. <br/>
Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый. <br/>
С подстветкой.`,
  },
  {
    id: 156,
    title: "Кресла ",
    old: null,
    new: "12 200 000",
    type: "",
    mebel: "Кресла ",
    name: "DL939A PU Leather",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "670W*715-765D*1210~1270H",
    img: img_156,
    subtitle: "Кресла ",
    description:
      `Кресло изготовленное из высококачественной эко кожи. Имеет многофункциональное шасси, регулируемая высота, со всеми 4-мя углами блокировки функции наклона. <br/>
Металлический подлокотник, алюминиевая пятизвездочная ножка с нейлоновыми роликами. <br/>
Губка высокой плотности. <br/>
Размер: 670W*715-765D*1210H. <br/>
Цвет: Серый`,
  },
  {
    id: 157,
    title: "Столы для руководителей ",
    old: null,
    new: "35 800 000",
    type: "МДФ",
    mebel: "Столы для руководителей ",
    name: "BDL-01D24",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*1800D*760H",
    img: img_157,
    subtitle: "Столы для руководителей ",
    description:
      `Руководительский стол из экологического шпона из МДФ класса E1.<br/>
Стол имеет встроенную длинную тумбу под принтер с отсеками для файлов и ящиками для хранения документов.<br/>
(Перед сборкой тумбу можно установить как справа так и слева). <br/>
Размер: 2400W*1800D*760H. <br/>
Цвет: Зеленый грейпфрут + коричневое золото + метеоритно-серый.`,
  },
  {
    id: 158,
    title: "Шкафы",
    old: null,
    new: "26 450 000",
    type: "Солид",
    mebel: "Шкафы",
    name: "YS-01S38C",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "910W*500D*2085H",
    img: img_158,
    subtitle: "Шкафы",
    description:
      `Шкаф из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску.<br/>
 Дверца из закаленного стекла темно-коричневого цвета. <br/>
Размер:910W*500D*2085H`,
  },
  {
    id: 159,
    title: "журнальные столики",
    old: null,
    new: "15 250 000",
    type: "Солид",
    mebel: "журнальные столики",
    name: "YS-01F16",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1600W*750D*420H",
    img: img_159,
    subtitle: "журнальные столики",
    description:
      `Журнальный стол из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску. <br/>
Размер:1600W*750D*420H`,
  },
  {
    id: 160,
    title: "Комоды",
    old: null,
    new: "21 900 000",
    type: "Солид",
    mebel: "Комоды",
    name: "YS-01H15",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1500W*450D*800H",
    img: img_160,
    subtitle: "Комоды",
    description:
      `Комод из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску. <br/>
Размер:1500W*450D*800H.`,
  },
  {
    id: 161,
    title: "Шкафы",
    old: null,
    new: "26 450 000",
    type: "Солид",
    mebel: "Шкафы",
    name: "YS-01S38A",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "910W*450D*2085H",
    img: img_161,
    subtitle: "Шкафы",
    description:
      `Шкаф из цельного дерева североамериканского черного ореха с облицовкой шпона- МДФ класса E1, имеет 8-слойную лаковую окраску.<br/>
Размер:910W*450D*2085H<br/>
Дверца из закаленного стекла темно-коричневого цвета. `,
  },
  {
    id: 162,
    title: "Шкафы",
    old: null,
    new: "35 250 000",
    type: "Солид",
    mebel: "Шкафы",
    name: "YS-01S38B",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1980W*500D*2085H",
    img: img_162,
    subtitle: "Шкафы",
    description:
      `Шкаф из цельного дерева североамериканского черного ореха, имеет раздвижные двери. <br/>
Размер:1980W*500D*2085H`,
  },
  {
    id: 163,
    title: "Столы для переговоров",
    old: null,
    new: "48 850 000",
    type: "Солид",
    mebel: "Столы для переговоров",
    name: "YS-01T36",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*750H",
    img: img_163,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из североамериканского черного ореха, столешница из МДФ класса E1 с натуральной облицовкой, 8-слойная лаковая окраска.<br/>
 Размер: 3600W*1500D*750H.`,
  },
  {
    id: 164,
    title: "Столы для руководителей",
    old: null,
    new: "47 550 000",
    type: "Солид",
    mebel: "Столы для руководителей",
    name: "YS-01D24",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*950D*760H",
    img: img_164,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из североамериканского черного ореха, столешница из МДФ класса E1 с натуральной облицовкой, 8-слойная лаковая окраска. <br/>
Размер: 2400W*950D*760H.`,
  },
  {
    id: 165,
    title: "Столы для руководителей",
    old: null,
    new: "41 600 000",
    type: "Солид",
    mebel: "Столы для руководителей",
    name: "YS-01D20",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2000W*900D*760H",
    img: img_165,
    subtitle: "Столы для руководителей",
    description:
      `Руководительский стол из североамериканского черного ореха, столешница из МДФ класса E1 с натуральной облицовкой, 8-слойная лаковая окраска.<br/>
 Размер: 2000W*900D*760H.`,
  },
  {
    id: 166,
    title: "Шкафы",
    old: null,
    new: "9 500 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "ETY-05S12    Optional 2",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1200*400*2000",
    img: img_166,
    subtitle: "Шкафы",
    description:
      `Шкаф трехстворчатый из МДФ класса E1, с фанерой из натурального ореха 0,6 мм, 8 слоев. <br/>
Имеются отсеки под файлы. Двери распашные с мягкими петлями. Закаленное стекло.`,
  },
  {
    id: 167,
    title: "Столы для переговоров",
    old: null,
    new: "30 600 000",
    type: "МДФ",
    mebel: "Столы для переговоров",
    name: "EJZ-01C36",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: img_167,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из МДФ класса E1 с шпоном натурального ореха 0,6 мм, 8 слоев открытого лака. <br/>
Размер 3600W*1500D*760H. С многофункциональным проводным тоннелем.`,
  },
  {
    id: 168,
    title: "Столы для переговоров",
    old: null,
    new: "15 800 000",
    type: "МДФ",
    mebel: "Столы для переговоров",
    name: "EJZ-02C24",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2400W*1200D*760H",
    img: img_168,
    subtitle: "Столы для переговоров",
    description:
      `Конференц стол из МДФ класса E1 с шпоном натурального ореха 0,6 мм, 8 слоев открытого лака. <br/>
С многофункциональным проводным тоннелем.`,
  },
  {
    id: 169,
    title: "Комоды",
    old: null,
    new: "11 400 000",
    type: "МДФ",
    mebel: "Комоды",
    name: "EUN-01H14",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1490W*400D*800H",
    img: img_169,
    subtitle: "Комоды",
    description:
      "Комод из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. Двери с мягкими закрытыми петлями. Имеет подвесные полки, алюминиевая ручка.",
  },
  {
    id: 170,
    title: "Шкафы",
    old: null,
    new: "43 850 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "EUN-01S34-1",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3400W*400D*2200",
    img: img_170,
    subtitle: "Шкафы",
    description:
      "Шкаф из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. Двери с мягкими закрытыми петлями. Имеет подвесные полки, алюминиевая ручка.",
  },
  {
    id: 171,
    title: "Шкафы",
    old: null,
    new: "38 050 000",
    type: "МДФ",
    mebel: "Шкафы",
    name: "EUN-01S29-2",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "2950W*400D*2200H",
    img: img_171,
    subtitle: "Шкафы",
    description:
      "Шкаф из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. Двери с мягкими закрытыми петлями. Имеет подвесные полки, алюминиевая ручка.",
  },
  // {
  //   id: 172,
  //   title: "Столы для руководителей | Open Varnish",
  //   old: null,
  //   new: "18 200 000",
  //   type: "МДФ",
  //   mebel: "Столы для руководителей | Open Varnish",
  //   name: "EUN-01S24-2",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: "2400W*2080D*760H",
  //   img: img_172,
  //   subtitle: "Столы для руководителей | Open Varnish",
  //   description:
  //     "Стол из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. В комплектацию стола входит встроенная тумба а также встроенная длинная тумба под принтер. Столешница оснащена бювером из черной эко-кожи для письма. Производство Китай.",
  // },
//   {
//     id: 173,
//     title: "Столы для руководителей",
//     old: null,
//     new: "14 700 000",
//     type: "МДФ",
//     mebel: "Столы для руководителей",
//     name: "EUN-01D20-1",
//     sale: null,
//     // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
//     size: "2000W*1780D*760H",
//     img: img_173,
//     subtitle: "Столы для руководителей",
//     description:
//       `Стол из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. <br/>
// Размер 2000W*1780D*760H. В комплектацию стола входит встроенная тумба а также встроенная длинная тумба под принтер. <br/>
// Столешница оснащена бювером из черной эко-кожи для письма. Производство Китай.`,
//   },
  {
    id: 174,
    title: "Столы для сотрудников",
    old: null,
    new: "10 950 000",
    type: "МДФ",
    mebel: "Столы для сотрудников",
    name: "EUN-01D16-1",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1600W*800D*760H",
    img: img_174,
    subtitle: "Столы для сотрудников",
    description:
      `Стол из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака.<br/>
Размер 1600W*800D*760H. В комплектацию стола входит маленькая тумба на роликах с ящиками для хранения документов и длинная тумба под принтер. Столешница оснащена бювером из черной эко-кожи для письма. Производство Китай.`,
  },
  {
    id: 175,
    title: "Столы для сотрудников",
    old: null,
    new: "6 700 000",
    type: "МДФ",
    mebel: "Столы для сотрудников",
    name: "EUN-01D14-1",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "1400W*700D*760H",
    img: img_175,
    subtitle: "Столы для сотрудников",
    description:
      `Стол из МДФ класса E1 с шпоном натурального ореха толщиной 0,6 мм, 8 слоев открытого лака. <br/>
Размер 1400W*700D*760H. В комплектацию стола входит тумба на роликах с ящиками для хранения документов. Столешница оснащена бювером из черной эко-кожи для письма. <br/>
Производство Китай.`,
  },
  // {
  //   id: 176,
  //   title: "Столы для переговоров",
  //   old: null,
  //   new: "26 135 000",
  //   type: "МДФ",
  //   mebel: "Столы для переговоров",
  //   name: "EJZ-01C36",
  //   sale: null,
  //   // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
  //   size: "3600W*1500D*760H",
  //   img: img_176,
  //   subtitle: "Столы для переговоров",
  //   description:
  //     "МДФ класса E1 с шпоном натурального ореха 0,6 мм, 8 слоев открытого лака. С многофункциональным проводным тоннелем.",
  // },
  {
    id: 177,
    slug:1,
    title: "",
    old: null,
    new: "85 600 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "Presidential Gold",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_1,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 178,
    title: "",
    slug:2,
    old: null,
    new: "67 650 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "Rose-Gold Royalty",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_7,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 179,
    title: "",
    slug:3,
    old: null,
    new: "45 850 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Wooden Loft” I",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_2,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 180,
    title: "",
    slug:4,
    old: null,
    new: "46 850 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Wooden Loft” II",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_2,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 181,
    slug:5,
    title: "",
    old: null,
    new: "38 600 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Nude Classic”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_3,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 182,
    slug:6,
    title: "",
    old: null,
    new: "91 450 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Rose Wood Premium”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_4,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 183,
    slug:7,
    title: "",
    old: null,
    new: "85 700 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Modern Blue”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_5,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 184,
    title: "",
    slug:8,
    old: null,
    new: "89 950 000",
    type: "МДФ",
    mebel: "Комплект",
    name: `"Solid-Wood Presidential” I`,
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_6,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 185,
    title: "",
    slug:9,
    old: null,
    new: "104 700 000",
    type: "МДФ",
    mebel: "Комплект",
    name: `"Solid-Wood Presidential” II`,
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    img: complete_6,
    subtitle: "Комплект",
    description:
      "",
  },
  
  
];

const CompleteMebels = [
  {
    id: 177,
    slug:1,
    title: "",
    old: null,
    new: "85 600 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "Presidential Gold",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_1,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 178,
    title: "",
    slug:2,
    old: null,
    new: "67 650 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "Rose-Gold Royalty",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_7,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 179,
    title: "",
    slug:3,
    old: null,
    new: "45 850 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Wooden Loft” I",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_2,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 180,
    title: "",
    slug:4,
    old: null,
    new: "46 850 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Wooden Loft” II",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_2,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 181,
    slug:5,
    title: "",
    old: null,
    new: "38 600 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Nude Classic”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_3,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 182,
    slug:6,
    title: "",
    old: null,
    new: "91 450 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Rose Wood Premium”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_4,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 183,
    slug:7,
    title: "",
    old: null,
    new: "85 700 000",
    type: "МДФ",
    mebel: "Комплект",
    name: "“Modern Blue”",
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_5,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 184,
    title: "",
    slug:8,
    old: null,
    new: "89 950 000",
    type: "МДФ",
    mebel: "Комплект",
    name: `"Solid-Wood Presidential” I`,
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_6,
    subtitle: "Комплект",
    description:
      "",
  },
  {
    id: 185,
    title: "",
    slug:9,
    old: null,
    new: "104 700 000",
    type: "МДФ",
    mebel: "Комплект",
    name: `"Solid-Wood Presidential” II`,
    sale: null,
    // img_list:[sdr_1,sdr_1,sdr_1,sdr_1],
    size: "3600W*1500D*760H",
    main_image: complete_6,
    subtitle: "Комплект",
    description:
      "",
  },

]
export {CompleteMebels, teamDataPiece, teamDataPiece2 ,dataPartner};
