import React from 'react'
//assets
import Advantages_1 from "../../assets/Image/about/Advantages_1.png"
import emblem from "../../assets/Image/about/emblem.png"

//css
import "../../style/advantages.css"
const Advantages = () => {
    const data=[
        {
            id:1,
            title:"Широкий ассортимент: В нашем каталоге Вы найдете разнообразные модели офисной мебели, сочетающие в себе стильный дизайн и функциональность.",
        },
        {
            id:2,
            title:"Качество и надежность: наша мебель изготовлена из высококачественных материалов, что обеспечивает долгий срок службы",
        },
        {
            id:3,
            title:"Эргономичность и комфорт: мы предлагаем эргономичную мебель сделанную из исключительно экологически чистых материалов E1, так как нам важно здоровье наших клиентов",
        },
        {
            id:4,
            title:"Профессиональные консультации: наша дружелюбная команда консультантов всегда готова помочь Вам с выбором и ориентировать по вопросам дизайна и функциональности",
        },
        {
            id:5,
            title:"Индивидуальный подход: мы всегда готовы подобрать мебель под Ваши потребности и пожелания.",
        },
    ]
  return (
    <div className='advantages'>
        <div className='advantages-img'>
            <img src={Advantages_1} alt="Advantages_1" />
        </div>
        <div className='advantages-text'>
            <h2>Наши достоинства выделяются следующим образом!</h2>
            <div className='advantages-textbox'>
                {data.map(item=>(
                    <div key={item.id} className='text-box'>
                        <div className='text-box-icon'><img src={emblem} alt="emblem" /></div>
                        <div className='text-box-title'>{item.title}</div>
                    </div>
                ))}
            </div>
        </div>
        <div className='advantages-img-res'>
            <img src={Advantages_1} alt="Advantages_1" />
        </div>
    </div>
  )
}

export default Advantages