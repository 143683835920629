import React, { useState } from 'react'
import call from "../../assets/Image/footer/icon_phone.svg"
import logo from "../../assets/Image/footer/bayir2.svg"
import {FaBars} from "react-icons/fa"
import "../../style/resnav.css"
import { AiOutlineClose } from 'react-icons/ai'
import { Link, useLocation } from 'react-router-dom'
import {GrNext,GrPrevious} from "react-icons/gr"
const Resnav = () => {
    const [firstnav,setFirstnav] = useState(false)
    const [secondnav,setSecondnav] = useState(false)
    const data = [
        {
            id:1,
            title:"Столы для руководителей",
            slug:"stol-dlya-rukovoditeley",
        },
        {
            id:2,
            title:"Столы для сотрудников",
            slug:"stol-dlya-sotrudnikov",
        },
        {
            id:3,
            title:"Столы для переговоров",
            slug:"stol-dlya-peregovorov",
        },
       
        {
            id:5,
            title:"Журнальные столики",
            slug:"jurnalniye-stoliki",
        },
        {
            id:6,
            title:"Столы Open Space",
            slug:"open-space",
        },
        
        {
            id:8,
            title:"Столы Reception",
            slug:"resepshn-stol",
        },
      
        {
            id:9,
            title:"Модульная Мебель",
            slug:"modulnaya-mebel",
        },
        {
            id:10,
            title:"Мягкая Мебель",
            slug:"myagkaya-mebel",
        },
        {
            id:11,
            title:"Шкафы",
            slug:"shkafi",
        },
        {
            id:12,
            title:"Комоды",
            slug:"komodi",
        },
        {
            id:13,
            title:"Кресла",
            slug:"kresla",
        },
    ]
  const {pathname} = useLocation()
    
    return (
    <div className='resnav'>
        <div className='nav-header'>
            <div className='resnav-logo'><Link to={"/"}><img src={logo} alt="logo" /></Link></div>
            <div className='resnav-icon' onClick={()=>setFirstnav((prev)=>!prev)}>{firstnav ?  <AiOutlineClose/>:<FaBars/>}</div>
        </div>
        <div className={`first-nav ${firstnav ? "close" : ""}`}>
           <Link className='nav-mebel' onClick={()=>setSecondnav(true)}  id={`${(pathname === "/mebel") ? "active" : ""}`} ><div>Мебель</div><div><GrNext/></div></Link>
           <Link to={"/about"} className='nav-item' id={`${(pathname === "/about") ? "active" : ""}`} onClick={()=>setFirstnav(false)}>О нас</Link>
           <Link to={"/partners"} className='nav-item' id={`${(pathname === "/partners") ? "active" : ""}`} onClick={()=>setFirstnav(false)}>Нам доверяют</Link>
           <Link to={"/delivery"} className='nav-item' id={`${(pathname === "/delivery") ? "active" : ""}`} onClick={()=>setFirstnav(false)}>Доставка и оплата</Link>
           <Link to={"/assembly"} className='nav-item' id={`${(pathname === "/assembly") ? "active" : ""}`} onClick={()=>setFirstnav(false)}>Сборка</Link>
           <Link to={"/contact"} className='nav-item' id={`${(pathname === "/contact") ? "active" : ""}`} onClick={()=>setFirstnav(false)}>Контакты</Link>
           <a className='resnav-phone' href='tel:+998712357447'>
                <img src={call} alt="call" />
                <p>+998 94 <span>210-74-47</span></p>
            </a>
        </div>
        <div className={`second-nav ${(secondnav && firstnav) ? "" : "close"}`}>
        <div className='sec-nav-mebel' onClick={()=>setSecondnav(false)}><div><GrPrevious/></div><div>Мебель</div></div>
            {data.map((item,key)=>(
                <Link className='sec-nav-item' key={key}  onClick={()=>setFirstnav(false)} to={`/mebel/${item.slug}`}>{item.title}</Link>
            ))}
        </div>
    </div>
  )
}

export default Resnav