import React, { useContext, useEffect, useState } from "react";
import Advantages from "../../components/advantages";
import "../../style/main.css";
import AosAnimation from "../../components/AosAnimation";


import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import main_3 from "../../assets/Image/main/img_main_3.png"
import main_4 from "../../assets/Image/main/img_main_4.jpeg"
import main_5 from "../../assets/Image/main/img_main_5.PNG"
import {teamDataPiece} from "../../data.db"
import MebelTypeLink from "../../mebelTypes"
import { ItemContext } from "../../context/ItemContext";
import { useNavigate } from "react-router-dom";
import Reviews from "../../components/reviews";
import MainSwiper from "../../components/mainSwiper";
import {dataPartner} from "../../data.db"
import Seo from "../../components/seo";
import { useSelector } from "react-redux";
const Main = () => {

    const {product,loading} = useSelector(state=>state.productSlice)
 
const navigate = useNavigate()
    const changeImg = (id) => {
        setImg(id);
    };
  const {setState,setItem} = useContext(ItemContext)

    const navigateItem = (item) =>{
        const filterUrl = MebelTypeLink.find(c=>c.typeLinkRu === fil)

        // setState(teamDataPiece.filter(c=>c.mebel === item.mebel))
        // setItem(item)
        navigate(`/mebel/${filterUrl.typeLinkUz}/${item.name}`)
      }
    const [img, setImg] = useState(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", setWindowDimensions);
        return () => {
            window.removeEventListener("resize", setWindowDimensions);
        };
    }, []);

    const [realIndex, setRealIndex] = useState(0);
    const [btn, setBtn] = useState(0);
    const [fil,setFilter] = useState("Комоды")
    return (
        <div className="main">
           <Seo title={'BAYIR | Мебель для офиса | Ташкент'} description={'Офисная мебель для ценителей комфорта и стиля'}/>
            <div className="main_top">
                <div>
                    <img src={main_4} alt="main_1" />
                    <div className="main-title">
                    <h1>Офисная мебель для ценителей комфорта и стиля</h1>
                    <h2>Воспользуйтесь нашими идеями, чтобы создать интерьер подходящий Вам</h2>
                    </div>
                </div>
                <div>
                    <img src={main_5} alt="main_2" />
                </div>
                <div>
                    <img src={main_3} alt="main_3" />
                </div>
            </div>
            <div className="partners">
                    <h1>Новинки</h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="main-buttons">
                        <button onClick={() => {
                            setBtn(0)
                            setFilter("Комоды")
                        }} className={btn === 0 ? "active" : ""}>
                            Комоды
                        </button>
                        <button onClick={()=>{
                            setBtn(1)
                            setFilter("Кресла")
                        }
                        } className={btn === 1 ? "active" : ""}>
                            Кресла
                        </button>
                        <button onClick={()=>{
                            setBtn(2)
                            setFilter("Шкафы")
                        }} className={btn === 2 ? "active" : ""}>
                            Шкафы
                        </button>
                        <button onClick={() => {
                            setBtn(3)
                            setFilter("Мягкая Мебель")
                        }} className={btn === 3 ? "active" : ""}>
                            Диваны
                        </button>
                    </div>
                </div>
                <div className="main-new">
                    <Swiper
                        slidesPerView={windowWidth > 1366 ? 4 : windowWidth > 1024 ? 3 : windowWidth > 768 ? 2 : 1}
                        spaceBetween={30}
                        navigation={true}
                        pagination={{
                            type: "progressbar",
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                        onSlideChange={(e) => {
                            setRealIndex(e.realIndex);
                        }}
                    >
                        {product?.filter(c=>c.mebel === fil).map((item,key) => (
                            <SwiperSlide key={key}>
                                <div className="piece-box-item">
                                    <div className="bg-img">
                                    <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>navigateItem(item)}>Подробнее</p>
            </div>
                                        {item.sale && (
                                            <p className="sale">{item.sale === null ? "" : item.sale + " %"}</p>
                                        )}
                                        <img src={item.main_image} alt={item.title} />
                                    </div>
                                    <div>
                                        <div className="cirlce">
                                            {/* <span className='box-circle' id='blue'></span>
                                            <span className='box-circle' id='white'></span>
                                            <span className='box-circle' id=''></span> */}
                                        </div>
                                        <div className="title-mini">{item.title}</div>
                                        <div className="piece-item-title">{item.name}</div>
                                        <div className="const">
                                            <b id="old">
                                                <strike>{item.old && item.old}</strike>
                                            </b>
                                            <b id="new">{item.new && item.new + " сум"}</b>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="main_pagination">
                            <p>{(realIndex + 1).toString().padStart(2, 0)}</p>
                            <p style={{ marginLeft: "363px" }}>{product?.filter(c=>c.mebel===fil).length}</p>
                        </div>
                    </Swiper>
                </div>
                <div className='res-main-new'>
                {product?.filter(c=>c.mebel===fil).map((item) => (
                                <div className="piece-box-item">
                                    <div className="bg-img">
                                    <div className='disp'>
            <span></span>
            <p id='pod' onClick={()=>navigateItem(item)}>Подробнее</p>
            </div>
                                        {item.sale && (
                                            <p className="sale">{item.sale === null ? "" : item.sale + " %"}</p>
                                        )}
                                        <img src={item.main_image} alt={item.title} />
                                    </div>
                                    <div>
                                        <div className="cirlce">
                                            {/* <span className='box-circle' id='blue'></span>
                                            <span className='box-circle' id='white'></span>
                                            <span className='box-circle' id=''></span> */}
                                        </div>
                                        <div className="title-mini">{item.title}</div>
                                        <div className="piece-item-title">{item.name}</div>
                                        <div className="const">
                                            <b id="old">
                                                <strike>{item.old && item.old}</strike>
                                            </b>
                                            <b id="new">{item.new && item.new + " сум"}</b>
                                        </div>
                                    </div>
                                </div>
                        ))}           
                                    
                </div>
            </div>
            <MainSwiper/>
            <div className="main-line"></div>
            <div className="partners">
                <h1>НАШИ ЗАРУБЕЖНЫЕ И МЕСТНЫЕ ПАРТНЕРЫ</h1>
                <div className="">
                    <Swiper
                        slidesPerView={windowWidth > 1366 ? 4 : windowWidth > 1024 ? 3 : windowWidth > 768 ? 2 : 2}
                        spaceBetween={30}
                        
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {dataPartner.map((item,key) => (
                            <SwiperSlide key={key}>
                                <AosAnimation dataoas="fade-up">
                                    <div
                                        style={{ backgroundImage: `url(${img === item.id ? item.img2 : item.img1})` }}
                                        onMouseOver={() => changeImg(item.id)}
                                        onMouseLeave={() => setImg(0)}
                                        className="partners-item"
                                    ></div>
                                </AosAnimation>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="main-line" style={{ marginBottom: 180, marginTop: 100 }}></div>
            <div className="about-advantages">
                <Advantages />
            </div>
            <div className="about-advantages">
                <Reviews />
            </div>
            {/* <Test/> */}
        </div>
    );
};

export default Main;
