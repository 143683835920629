import axios from "../api/api"


const AuthService = {
    async ProductService() {
        const {data} = await axios.get('/api/products')
        return data
    },
    
}
export default AuthService