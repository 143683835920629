import React from 'react'

//assets
import logo from "../../assets/Image/footer/bayir1.svg"
import Catalogpdf from "../../Bayir.pdf"

//icons
import {FaFacebookF, FaTelegramPlane} from "react-icons/fa"
import {AiOutlineInstagram} from 'react-icons/ai'

// style css
import "../../style/footer.css"

//router
import { Link } from 'react-router-dom'

const Footer = () => {

  const handleClick = () =>{
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className='footer'>
        <div className='footer_logo' onClick={handleClick}>
            <Link to={'/'}><img src={logo} alt="logo" /></Link>
        </div>
        <div className='footer_nav'>
            <Link  href={Catalogpdf} target='_blank' onClick={handleClick}> <a href={Catalogpdf} target='_blank'>Каталог</a> </Link>
            <Link to={'/about'} onClick={handleClick}>О нас</Link>
            <Link to={"/delivery"} onClick={handleClick}>Доставка и оплата</Link>
            <Link to={"/assembly"} onClick={handleClick}>Сборка</Link>
            <Link to={'/contact'} onClick={handleClick}>Контакты</Link>
        </div>
        <div className='footer_social'>
            <Link to={'https://t.me/mebel_Bayir_Tashkent'}><FaTelegramPlane/></Link>
            <Link to={'https://www.instagram.com/bayirmebel.uz'}><AiOutlineInstagram/></Link>
            <Link to={'https://m.facebook.com/BAYIRmebel'}><FaFacebookF/></Link>
        </div>
    </div>
  )
}

export default Footer